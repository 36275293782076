<template>
  <div>
    <header-template
        ref="headerRef"
        :title="title"
        :items="items"
        :items-context="itemsContext"
        :display-custom-fields="displayCustomFields"
        :search-query.sync="searchQuery"
        :filters.sync="filters"
        :filters-a-p-i="payload.filters"
        :available-filters="availableFilters"
        :sorts.sync="sorts"
        :sorts-a-p-i="payload.sorts"
        :available-sorts="availableSorts"
        :available-views="availableViews"
        :available-global-amounts="availableGlobalAmounts"
        :selected-view.sync="selectedView"
        :read-only="readOnly"
        :module="module"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :allow-payment="allowPayment"
        :is-button-add-disabled="isButtonAddDisabled"

        @addItem="$emit('addItem')"
        @addItemByUpload="$emit('addItemByUpload')"
        @resetPayload="resetPayload"
    />

    <kanban-view
        v-if="selectedView == 'kanban' && !openViewWindow"
        ref="kanbanView"
        :items="items"
        :current-view="currentView"
        :context="itemsContext"
        :page.sync="page"

        @tryToUpdateItem="$emit('tryToUpdateKanbanItem', $event)"
        @updatePayload="$emit('updatePayload', $event)"
    >
      <template #kanbanItemView="{item}">
        <slot
            name="kanbanItemView"
            :item="item"
        />
      </template>
    </kanban-view>

    <list-view
        v-if="selectedView == 'list' && !openViewWindow"
        ref="listViewRef"
        :items="items"
        :current-view="currentView"
        :is-action-column-enabled="isListViewActionColumnEnabled"
        :selectable="listViewSelectable"
        :allow-view-windows="allowViewWindows"

        @listSort="sorts=[$event]"
        @rowSelected="$emit('selectedItems', $event)"
        @selectItem="$emit('showDetails', $event);openViewWindow=true"
        @showDetails="$emit('showDetails', $event);openViewWindow=true"
    >
      <template
          v-for="slot in slotsNames"
          v-slot:[slot]="{item}"
      >
        <slot
            :name="slot"
            :item="item"
        />
      </template>
    </list-view>

    <div
        v-if="openViewWindow"
        class="col-12"
    >
      <view-window
          :items="items"
          :open-view-window.sync="openViewWindow"
          :is-view-windows-ready="isViewWindowsReady"
      >
        <template #leftViewWindow="{item}">
          <slot
              name="leftViewWindow"
              :item="item"
          />
        </template>

        <template #title>
          <slot name="viewWindowTitle"/>
        </template>

        <template #buttons>
          <slot name="viewWindowButtons"/>
        </template>

        <template #windowView>
          <slot name="windowView"/>
        </template>
      </view-window>
    </div>

    <pagination
        v-if="!['kanban','map'].includes(selectedView)"
        :items-context="itemsContext"
        :page.sync="page"
    />

  </div>
</template>

<script>
import { ref, computed, watch }              from '@vue/composition-api'
import { capitalize }                        from '../../utils/filter'
import { getUserData, isSameObjectsContent } from '../../utils/utils'

import store from '../../store'

import HeaderTemplate from './header/Header.vue'
import Pagination     from './footer/Pagination.vue'
import ListView       from './views/ListView.vue'
import KanbanView     from './views/KanbanView.vue'
import ViewWindow     from './views/ViewWindow.vue'

export default {
  components: {
    ViewWindow,
    KanbanView,
    ListView,
    Pagination,
    HeaderTemplate
  },
  props: {
    title: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsContext: {
      type: Object,
      default: () => {}
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },
    availableFilters: {
      type: Array,
      default: () => []
    },
    availableGlobalAmounts: {
      type: Array,
      default: () => []
    },
    payload: {
      type: Object,
      default: () => {}
    },
    module: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isViewWindowsReady: {
      type: Boolean,
      default: false
    },
    isListViewActionColumnEnabled: {
      type: Boolean,
      default: false
    },
    listViewSelectable: {
      type: String,
      default: null
    },
    allowViewWindows: {
      type: Boolean,
      default: false
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    allowPayment: {
      type: Boolean,
      default: false
    },
    isButtonAddDisabled: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const headerRef = ref()
    const listViewRef = ref()
    const searchQuery = ref('')
    const sorts = ref(props.payload.sorts)
    const filters = ref(props.payload.filters)
    const page = ref(props.payload.page)
    const perPage = ref(props.payload.per_page)
    const selectedView = ref(getUserData().defaultViews[props.module].type)
    const openViewWindow = ref(false)

    const refreshKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const availableSorts = computed(() => {
      return props.availableFilters.map(({ field, i18n }) => {
        return {
          field: field,
          i18n: i18n
        }
      })
    })

    const filtersAPILocal = computed(() => {
      let output = []

      if (searchQuery.value != '') {
        output.push({
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: 'AND',
              field: '_all',
              value: searchQuery.value
            }
          ]
        })
      }

      if (filters.value.length != 0) {
        output = [...output, ...filters.value]
      }

      return output
    })

    const payloadLocal = computed(() => {
      return {
        filters: filtersAPILocal.value,
        kanbanSortBy: props.payload.kanbanSortBy,
        kanbanColumnAmount: props.payload.kanbanColumnAmount,
        listGlobalAmount: props.payload.listGlobalAmount,
        page: page.value,
        sorts: sorts.value,
      }
    })

    const availableViews = computed(() => {
      // console.log(store.getters['moduleView/get' + capitalize(props.module) + 'ModuleViews'])
      return store.getters['moduleView/get' + capitalize(props.module) + 'ModuleViews']
    })

    const currentView = computed(() => {
      refreshKey.value

      return store.getters['moduleView/getDefaultViewByModule'](selectedView.value, props.module)
    })

    const slotsNames = computed(() => {
      let slotsNames = []
      if (currentView.value && ('listColumns' in currentView.value)) {
        currentView.value.listColumns.forEach(lc => {
          slotsNames.push('listView_head_' + lc.key)
          slotsNames.push('listView_cell_' + lc.key)
        })
      }

      return slotsNames
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payloadLocal, val => {
      if (JSON.stringify(props.payload) != JSON.stringify(val)) {
        emit('update:payload', val)
      }
    }, { deep: true })

    watch(() => props.isViewWindowsReady, val => {
      if (val == true) {
        openViewWindow.value = true
      }
    })

    watch(openViewWindow, val => {
      if (val == false) {
        emit('resetDisplayedViewWindow')
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const closeViewWindow = () => {
      openViewWindow.value = false
      emit('update:isViewWindowsReady', false)
    }

    const resetPayload = () => {
      let currentViewUpdated = store.getters['moduleView/getModuleView'](getUserData().defaultViews[currentView.value.module].id)
      selectedView.value = currentViewUpdated.type
      filters.value = currentViewUpdated.filters
      sorts.value = currentViewUpdated.sorts
      refreshKey.value++

      emit('update:payload', {
        filters: currentViewUpdated.filters,
        kanbanSortBy: currentViewUpdated.kanbanSortBy,
        kanbanColumnAmount: currentViewUpdated.kanbanColumnAmount,
        listGlobalAmount: currentViewUpdated.listGlobalAmount,
        page: 1,
        sorts: currentViewUpdated.sorts
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      headerRef,
      listViewRef,
      searchQuery,
      sorts,
      filters,
      selectedView,
      page,
      perPage,
      openViewWindow,

      // Computed
      availableSorts,
      availableViews,
      currentView,
      slotsNames,

      // Methods
      closeViewWindow,
      resetPayload,
    }
  },
  data () {
    return {}
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  methods: {}
  ,
  mounted () {
  }
  ,
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>