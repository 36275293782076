<template>
  <form-products
      :products.sync="order.products"
      @changePreTaxAmount="changePreTaxAmount"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FormProducts from '@/components/form/products/Products'

export default {
  components: {
    FormProducts
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const changePreTaxAmount = (input = null, value = null) => {
      let subPreTaxAmount = 0
      props.order.preTaxAmount = 0
      props.order.vat = 0
      props.order.totalAmount = 0

      props.order.products.forEach(product => {
        // console.log(product)
        subPreTaxAmount += parseFloat(product.preTaxAmount)
        props.order.vat += parseFloat(product.vat)
      })

      let discount = parseFloat(props.order.discount ? props.order.discount : 0)
      let isDiscountPercent = props.order.isDiscountPercent
      if (input == 'discount') {
        if (typeof value === 'boolean') {
          isDiscountPercent = value
        } else {
          discount = parseFloat(value ? value : 0)
        }
      }

      if (discount != 0) {
        if (isDiscountPercent) {
          props.order.preTaxAmount = parseFloat(
              subPreTaxAmount * (1 - discount / 100)
          ).toFixed(2)

          props.order.vat = parseFloat(
              props.order.vat * (1 - discount / 100)
          ).toFixed(2)

        } else {
          props.order.preTaxAmount = subPreTaxAmount - discount
          props.order.vat = 0

          let discountPercent = discount / subPreTaxAmount

          props.order.products.forEach(product => {
            let discountedPreTaxAmount = parseFloat(product.preTaxAmount) * (1 - discountPercent)
            let rate = store.getters['vat/getVat'](product.vatRate).rate

            props.order.vat += discountedPreTaxAmount * rate
          })

        }
      } else {
        props.order.preTaxAmount = subPreTaxAmount
      }

      if (props.order.preTaxAmount < 0) {
        props.order.preTaxAmount = 0
        props.order.vat = 0
      }

      // let duty = parseFloat(props.order.duty ? props.order.duty : 0)
      // props.order.preTaxAmount += duty
      // props.order.vat += parseFloat(duty * 0.2).toFixed(2)

      let tax = parseFloat(props.order.tax ? props.order.tax : 0)

      props.order.totalAmount =
          parseFloat(props.order.preTaxAmount) +
          parseFloat(props.order.vat) +
          tax

      // componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
      changePreTaxAmount,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>