<template>

  <generic-card
      :byContent="true"
      :allowDelete="allowDelete"

      @click="$emit('click')"
      @delete="$emit('deleteOrder')"

  >
    <template #content>
      <content-order
          :order="order"
          :isParentMethodPriority="true"
          :isPayable="isPayable"
          :isDownloadable="isDownloadable"
          :isViewable="isViewable"
          :isDeletable="false"
          :is-selected="isSelected"
          @view="$emit('view')"
          @pay="$emit('pay')"
      />
    </template>

  </generic-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericCard from './Card'
import ContentOrder from './components/Order.vue'

export default {
  components: {
    GenericCard,
    ContentOrder
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    isDownloadable: {
      type: Boolean,
      default: true
    },
    isViewable: {
      type: Boolean,
      default: false,
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>