<template>
  <div class="row">

    <div class="col-md-4">
      <select2
          :options="operatorList"
          :displayLabel="false"
          size="sm"
          :model.sync="operatorLocal"
          :clearable="false"
          :disabled="isDisabled"
          label="i18n"
      />
    </div>

    <div class="col-md-8">
      <select2
          :options="correspondingAvailableFilter.options"
          label="i18n"
          reduce="value"
          :displayLabel="false"
          size="sm"
          :model.sync="selectedValueLocal"
          :disabled="isSelectedValueDisabled"
      />
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import select2 from '../../../input/Select2.vue'
import { capitalize } from '../../../../utils/filter'
import i18n from '../../../../libs/i18n'

export default {
  components: { select2 },
  props: {
    sectionFilter: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    correspondingAvailableFilter: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, {emit}) {
    console.log(props.sectionFilter)
    console.log(props.correspondingAvailableFilter)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const operatorLocal = ref({
      value: props.sectionFilter.operator,
      i18n: capitalize(i18n.t(props.sectionFilter.operator))
    })

    const operatorList = ref([
      {
        value: 'equal',
        i18n: capitalize(i18n.t('equal'))
      },
      {
        value: 'not_equal',
        i18n: capitalize(i18n.t('not_equal'))
      },
      {
        value: 'is_empty',
        i18n: capitalize(i18n.t('is_empty'))
      },
      {
        value: 'is_not_empty',
        i18n: capitalize(i18n.t('is_not_empty'))
      },
    ])

    const selectedValueLocal = ref(props.sectionFilter.value)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isSelectedValueDisabled = computed(() => {
      if (
          props.isDisabled ||
          (
              operatorLocal.value != null &&
              (
                  operatorLocal.value.value == 'is_empty' ||
                  operatorLocal.value.value == 'is_not_empty'
              )
          )
      ) {
        return true
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(operatorLocal, val => {
      if (
          val != null &&
          (
              val.value == 'is_empty' ||
              val.value == 'is_not_empty'
          )) {
        selectedValueLocal.value = null
      }

      parentUpdate()
    })
    watch(selectedValueLocal, val => {
      parentUpdate()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const parentUpdate = () => {
      if (!props.isDisabled) {
        if (['is_empty', 'is_not_empty'].includes(operatorLocal.value.value)) {
          // console.log("B", {
          //   name: props.sectionFilter.name,
          //   logicOperator: props.sectionFilter.logicOperator,
          //   field: props.sectionFilter.field,
          //   operator: operatorLocal.value.value,
          //   subFields: props.sectionFilter.subFields,
          //   value: null
          // })
          emit('update:sectionFilter', {
            name: props.sectionFilter.name,
            logicOperator: props.sectionFilter.logicOperator,
            field: props.sectionFilter.field,
            operator: operatorLocal.value.value,
            subFields: props.sectionFilter.subFields,
            value: null
          })
        } else {
          // console.log("A", JSON.parse(JSON.stringify({
          //   name: props.sectionFilter.name,
          //   logicOperator: props.sectionFilter.logicOperator,
          //   field: props.sectionFilter.field,
          //   operator: operatorLocal.value.value,
          //   subFields: props.sectionFilter.subFields,
          //   value: selectedValueLocal.value
          // })))
          emit('update:sectionFilter', {
            name: props.sectionFilter.name,
            logicOperator: props.sectionFilter.logicOperator,
            field: props.sectionFilter.field,
            operator: operatorLocal.value.value,
            subFields: props.sectionFilter.subFields,
            value: selectedValueLocal.value
          })
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      operatorLocal,
      operatorList,
      selectedValueLocal,

      // Computed
      isSelectedValueDisabled,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>