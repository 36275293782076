<template>
  <div class="row">

    <div class="col-md-4">
      <select2
          :options="operatorList"
          :displayLabel="false"
          size="sm"
          :model.sync="operatorLocal"
          :clearable="false"
          :disabled="isOperatorDisabled"
          label="i18n"
      />
    </div>

    <div class="col-md-8">
      <b-row>
        <b-col :md="operatorLocal && operatorLocal.value=='between'?6:12">
          <date-field
              :model.sync="inputValueLocal1"
              size="sm"
              class="my-auto flatPickrSmSize"
              :disabled="isInputValueDisabled"
          />
        </b-col>
        <b-col
            v-if="operatorLocal && operatorLocal.value=='between'"
            md="6"
        >
          <date-field
              v-if="operatorLocal.value == 'between'"
              :model.sync="inputValueLocal2"
              :min-date="inputValueLocal1"
              size="sm"
              class="my-auto flatPickrSmSize"
              :disabled="isInputValueDisabled"
          />
        </b-col>

      </b-row>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../utils/filter'

import select2   from '../../../input/Select2.vue'
import DateField from '../../../input/Date.vue'
import i18n      from '../../../../libs/i18n'

export default {
  components: {
    select2,
    DateField,
  },
  props: {
    sectionFilter: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(props.sectionFilter)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const operatorLocal = ref({
      value: props.sectionFilter.operator,
      i18n: capitalize(i18n.t(props.sectionFilter.operator))
    })
    const operatorList = ref([
      {
        value: 'equal',
        i18n: capitalize(i18n.t('equal'))
      },
      {
        value: 'not_equal',
        i18n: capitalize(i18n.t('not_equal'))
      },
      {
        value: 'before',
        i18n: capitalize(i18n.t('before'))
      },
      {
        value: 'after',
        i18n: capitalize(i18n.t('after'))
      },
      {
        value: 'between',
        i18n: capitalize(i18n.t('between'))
      },
      {
        value: 'is_empty',
        i18n: capitalize(i18n.t('is_empty'))
      },
      {
        value: 'is_not_empty',
        i18n: capitalize(i18n.t('is_not_empty'))
      },
    ])

    const inputValueLocal1 = ref(
        (typeof props.sectionFilter.value === 'object' && Array.isArray(props.sectionFilter.value)) ? props.sectionFilter.value[0] : props.sectionFilter.value
    )
    const inputValueLocal2 = ref(
        (typeof props.sectionFilter.value === 'object' && Array.isArray(props.sectionFilter.value)) ? props.sectionFilter.value[1] : null
    )

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isInputValueDisabled = computed(() => {
      // console.log(props.sectionFilter)
      // console.log(typeof props.sectionFilter._isInputValueDisabled)
      // console.log(typeof props.sectionFilter._isInputValueDisabled != undefined)

      // if (
      //     typeof props.sectionFilter._isInputValueDisabled != undefined &&
      //     props.sectionFilter._isInputValueDisabled == false
      // ) {
      //   return false
      // } else {
      if (props.isDisabled ||
          (
              operatorLocal.value != null &&
              (
                  operatorLocal.value.value == 'is_empty' ||
                  operatorLocal.value.value == 'is_not_empty'
              )
          ) || (
              ('_isInputValueDisabled' in props.sectionFilter) &&
              props.sectionFilter._isInputValueDisabled == true
          )
      ) {
        return true
      } else {
        return false
      }
      // }

    })

    const isOperatorDisabled = computed(() => {
      return props.isDisabled || ('_operatorDisabled' in props.sectionFilter && props.sectionFilter._operatorDisabled == true)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(operatorLocal, val => {
      if (
          val != null &&
          (
              val.value == 'is_empty' ||
              val.value == 'is_not_empty'
          )) {
        inputValueLocal1.value = null
        inputValueLocal2.value = null
      }

      parentUpdate()
    })

    watch(inputValueLocal1, val => {
      parentUpdate()
    })
    watch(inputValueLocal2, val => {
      parentUpdate()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const parentUpdate = () => {
      if (!props.isDisabled && operatorLocal.value) {
        if (['is_empty', 'is_not_empty'].includes(operatorLocal.value.value)) {
          emit('update:sectionFilter', {
            name: props.sectionFilter.name,
            logicOperator: props.sectionFilter.logicOperator,
            field: props.sectionFilter.field,
            operator: operatorLocal.value.value,
            subFields: props.sectionFilter.subFields,
            value: null,
            _sectionOperatorDisabled: props.sectionFilter._sectionOperatorDisabled,
            _fieldDisabled: props.sectionFilter._fieldDisabled,
            _operatorDisabled: props.sectionFilter._operatorDisabled,
            _allowDelete: props.sectionFilter._allowDelete,
            _isInputValueDisabled: props.sectionFilter._isInputValueDisabled
          })
        } else if (
            operatorLocal.value.value == 'between'
        ) {
          if (
              inputValueLocal1.value != null &&
              inputValueLocal2.value != null) {
            emit('update:sectionFilter', {
              name: props.sectionFilter.name,
              logicOperator: props.sectionFilter.logicOperator,
              field: props.sectionFilter.field,
              operator: operatorLocal.value.value,
              subFields: props.sectionFilter.subFields,
              value: [inputValueLocal1.value, inputValueLocal2.value],
              _sectionOperatorDisabled: props.sectionFilter._sectionOperatorDisabled,
              _fieldDisabled: props.sectionFilter._fieldDisabled,
              _operatorDisabled: props.sectionFilter._operatorDisabled,
              _allowDelete: props.sectionFilter._allowDelete,
              _isInputValueDisabled: props.sectionFilter._isInputValueDisabled
            })
          }
        } else {
          if (inputValueLocal1.value != null) {
            emit('update:sectionFilter', {
              name: props.sectionFilter.name,
              logicOperator: props.sectionFilter.logicOperator,
              field: props.sectionFilter.field,
              operator: operatorLocal.value.value,
              subFields: props.sectionFilter.subFields,
              value: inputValueLocal1.value,
              _sectionOperatorDisabled: props.sectionFilter._sectionOperatorDisabled,
              _fieldDisabled: props.sectionFilter._fieldDisabled,
              _operatorDisabled: props.sectionFilter._operatorDisabled,
              _allowDelete: props.sectionFilter._allowDelete,
              _isInputValueDisabled: props.sectionFilter._isInputValueDisabled
            })
          }
        }
      }
    }

    // const initPopulate = () => {
    //   if (
    //       typeof props.sectionFilter.value === 'object' &&
    //       Array.isArray(typeof props.sectionFilter.value) &&
    //       props.sectionFilter.value.length == 2
    //   ) {
    //     inputValueLocal1.value = props.sectionFilter.value[0]
    //     inputValueLocal2.value = props.sectionFilter.value[1]
    //   } else {
    //     inputValueLocal1.value = props.sectionFilter.value
    //     inputValueLocal2.value = props.sectionFilter.value
    //   }
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // initPopulate()

    return {
      // Components
      capitalize,

      // Data
      operatorLocal,
      operatorList,
      inputValueLocal1,
      inputValueLocal2,

      // Computed
      isInputValueDisabled,
      isOperatorDisabled,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.flatPickrSmSize {
  .form-control {
    height: 30.344px;
  }
}

</style>