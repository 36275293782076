<template>
  <div class="col-md-12">
    <b-card class="baseHeaderSortDetails">

      <div class="w-100 d-flex justify-content-between">
        <div class="w-100"><h2>{{ capitalize($t('vues')) }}</h2></div>
        <div>
          <button-add
              @click="$emit('addModuleView')"
              :withIcon="true"
              size="sm"
          />
        </div>
      </div>

      <b-table
          responsive
          :items="myConcernedViews"
          :fields="columns"
          striped
          hover
          :key="componentKey"
          @row-clicked="$emit('selectItem', $event)"
      >
        <template #head(name)="{field}">
          {{ field.display }}
        </template>
        <template #cell(name)="{item}">
          <span v-if="item._createdBy == null">
            {{ capitalize($t(item.type)) + " " + $t('byDefault')}}
          </span>
          <span v-else>
            {{ capitalize($t(item.name)) }}
          </span>
        </template>
        <template #cell(type)="{item}">
          {{ capitalize($t(item.type)) }}
        </template>
        <template #cell(actions)="{item}">
          <check-button
              v-if="!isCurrentView(item)"
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              size="sm"

              @click="$emit('setDefaultModuleView', {moduleView:item})"
          />
          <stop-button
              v-else
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              :is-disabled="true"
              size="sm"
          />
          <edit-button
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              :disabled="isModuleViewDisabled(item)"
              size="sm"

              @click="$emit('editModuleView', item)"
          />
          <duplicate-button
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              size="sm"

              @click="$emit('deplicateModuleView', item)"
          />
          <delete-button
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              :disabled="isModuleViewDisabled(item)"
              size="sm"

              @click="$emit('deleteModuleView', item)"
          />

        </template>


      </b-table>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import DeleteButton from '../../button/Delete.vue'
import StopButton from '../../button/Stop.vue'
import CheckButton from '../../button/Check.vue'
import ButtonAdd from '../../button/Add.vue'
import DuplicateButton from '../../button/Duplicate.vue'
import EditButton from '../../button/Edit.vue'
import i18n from '../../../libs/i18n'
import store from '../../../store'
import { getUserData } from '../../../utils/utils'

export default {
  components: { EditButton, DuplicateButton, ButtonAdd, CheckButton, StopButton, DeleteButton },
  props: {
    module: {
      type: String
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      { key: 'name', 'sortable': true, display: capitalize(i18n.t('name')) },
      { key: 'type', 'sortable': true, display: capitalize(i18n.t('type')) },
      { key: 'actions', display: i18n.tc('action', 2) }
    ])
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const myConcernedViews = computed(() => {
      return store.getters['moduleView/getMyConcernedViewsByModule'](props.module)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(() => store.getters['moduleView/getModuleViews'], val => {
    //   console.log(val)
    // }, {deep: true})
    //
    //
    // watch(myConcernedViews, val => {
    //   console.log(val)
    // }, {deep: true})

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const isCurrentView = (moduleView) => {
      return moduleView.id == getUserData().defaultViews[props.module].id
    }

    const isModuleViewDisabled = (moduleView) => {
      return moduleView._createdBy == null
    }

    const update = () => {
      emit('resetPayload')
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      columns,
      componentKey,

      // Computed
      myConcernedViews,

      // Methods
      isCurrentView,
      isModuleViewDisabled,
      update,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>