<template>
  <div class="row">

    <div class="col-md-12">
      <select2
          :options="correspondingAvailableFilter.options"
          label="i18n"
          reduce="value"
          :displayLabel="false"
          size="sm"
          :model.sync="selectedValueLocal"
          :disabled="isSelectedValueDisabled"
      />
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import select2 from '../../../input/Select2.vue'
import { capitalize } from '../../../../utils/filter'
import i18n from '../../../../libs/i18n'

export default {
  components: { select2 },
  props: {
    sectionFilter: {
      type: Object,
      default: () => {}
    },
    correspondingAvailableFilter: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const operatorLocal = ref({
      value: 'equal',
      i18n: capitalize(i18n.t('equal'))
    })

    const selectedValueLocal = ref(props.sectionFilter.value)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isSelectedValueDisabled = computed(() => {
      return props.isDisabled
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedValueLocal, val => {
      parentUpdate()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const parentUpdate = () => {
      if (!props.isDisabled) {
          emit('update:sectionFilter', {
            logicOperator: props.sectionFilter.logicOperator,
            name: props.sectionFilter.name,
            field: props.sectionFilter.field,
            operator: operatorLocal.value.value,
            subFields: props.sectionFilter.subFields,
            value: selectedValueLocal.value
          })
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      operatorLocal,
      selectedValueLocal,

      // Computed
      isSelectedValueDisabled,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>