<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('order',2)"
        :items="orders2"
        :items-context="ordersContext"
        :available-filters="$store.getters['order2/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="$can('manage', 'orders')"
        :is-view-windows-ready="displayedOrder!=null"

        @addItem="orderModalShow=true"
        @showDetails="showDetails"
    >
      <template #listView_cell_status="{item}">
        <b-avatar
            :id="`order-row-${item.id}`"
            size="32"
            :variant="`light-${getOrderIcon(item._status).variant}`"
        >
          <icon
              :icon="getOrderIcon(item._status).icon"
          />
        </b-avatar>
        <b-tooltip
            :target="`order-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ $t(item._status) }}
          </p>
        </b-tooltip>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editOrderLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :disabled="item.number != null || !$can('manage', 'Orders')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteOrderAlert(item)"
            :disabled="item.number != null || !$can('manage', 'Orders')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>


      <template #leftViewWindow="{item}">
        <order-card
            :order="item"
            :is-viewable="true"
            :is-selected="item.id==displayedOrder.id"
            :is-edit-allowed="false"
            :is-trash-allowed="false"
            :is-downloadable="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedOrder.number }}
      </template>
      <template #viewWindowButtons>
        <!--          Edit -->
        <button-edit
            @click.native.stop="editOrderLocal(displayedOrder)"
            v-b-tooltip.hover.left="$t('Edit')"
            :disabled="displayedOrder.number != null || !$can('manage', 'Orders')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>
      <template #windowView>
        <order-view :order="displayedOrder"/>
      </template>
    </base-template>

    <!-- modal supplier invoice -->
    <modal-order
        :order.sync="currentOrder"
        :title="('id' in currentOrder)?$t('EditOrder'):$t('NewOrder')"
        :is-open.sync="orderModalShow"
        @submitValidatedOrder="submitValidatedOrderLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { getRoute } from '../../utils/utils'
import { capitalize } from '../../utils/filter'
import { useOrders } from '../../views/purchases/orders/useOrders'

import useAPI from '../../utils/useAPI'
import store from '../../store'

import BaseTemplate from '../base3/Base.vue'
import ButtonEdit from '../button/Edit.vue'
import ButtonRemove from '../button/Remove.vue'
import OrderCard from '../card/Order2.vue'
import OrderView from './OrderView.vue'
import ModalOrder from '../prompt/Order.vue'

export default {
  components: { ModalOrder, OrderView, OrderCard, ButtonRemove, ButtonEdit, BaseTemplate },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const module = ref('orders')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedOrder = ref(null)
    const currentOrder = ref(null)
    const orderModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { orders2, ordersContext, autocompleteWorkFlos } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      if (getRoute().name == 'WorkFlo view') {
        emit('updatePayload', val)
      } else {
        fetchOrders2(val)
            .then(() => {
              if (!isAutocompleteLoaded.value) {
                loadInit()
              }
            })
      }

    }, { deep: true })

    watch(orderModalShow, val => {
      if (val == false) {
        resetOrder()
      }
    })

    watch(autocompleteWorkFlos, val => {
      resetOrder()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchOrders2,

    } = useAPI()

    const {
      submitValidatedOrder,
      removeOrder,
    } = useOrders()

    const loadInit = () => {

      isAutocompleteLoaded.value = true
    }

    const showDetails = (order) => {
      if (order != null) {
        store.dispatch('order2/getOrder', order.id)
            .then(response => {
              displayedOrder.value = response
            })

      } else {
        resetOrder()
      }
    }

    const resetOrder = () => {
      currentOrder.value = JSON.parse(JSON.stringify(store.getters['order2/getEmptyOrder']))

      if (getRoute().name == 'WorkFlo view') {
        if (!autocompleteWorkFlos.value.length) {
          currentOrder.value.workFlo = {
            id: parseInt(getRoute().params.workFloId)
          }
        } else {
          currentOrder.value.workFlo = store.getters['workFlo/getAutocompleteWorkFlos'].find(w => w.id == parseInt(getRoute().params.workFloId))
        }
      }
    }

    const editOrderLocal = (order) => {
      store.dispatch('order2/getOrder', order.id)
          .then(response => {
            currentOrder.value = response
            orderModalShow.value = true
          })
    }

    const removeOrderLocal = (order) => {
      removeOrder(order)
          .then(response => {

          })
    }

    const submitValidatedOrderLocal = () => {
      // console.log(currentOrder.value)
      submitValidatedOrder(currentOrder.value)
          .then(response => {
            resetOrder()
            orderModalShow.value = false
            displayedOrder.value = response.data
          })
    }

    const getOrderIcon = (status) => {
      if (status == 'Draft') return { variant: 'secondary', icon: 'pencil-ruler' }
      else if (status == 'Archived') return { variant: 'secondary', icon: 'box' }
      else if (status == 'InternalValidated') return { variant: 'primary', icon: 'check' }
      else if (status == 'Accepted') return { variant: 'warning', icon: 'hourglass' }
      else if (status == 'Invoiced') return { variant: 'success', icon: 'file-invoice-dollar' }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetOrder()

    if (getRoute().name != 'WorkFlo view') {
      store.dispatch('moduleView/getModuleViews')
          .then((response) => {
            let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

            payload.value = {
              filters: currentView.filters,
              kanbanSortBy: currentView.kanbanSortBy,
              kanbanColumnAmount: currentView.kanbanColumnAmount,
              listGlobalAmount: currentView.listGlobalAmount,
              page: 1,
              sorts: currentView.sorts,
            }

          })
    } else {
      let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

      payload.value = {
        filters: currentView.filters,
        kanbanSortBy: currentView.kanbanSortBy,
        kanbanColumnAmount: currentView.kanbanColumnAmount,
        listGlobalAmount: currentView.listGlobalAmount,
        page: 1,
        sorts: currentView.sorts,
      }
    }

    return {
      // Components
      capitalize,

      // Data
      baseTemplate,
      module,
      payload,
      isReady,

      displayedOrder,
      currentOrder,
      orderModalShow,

      // Computed
      orders2,
      ordersContext,

      // Methods
      showDetails,
      editOrderLocal,
      removeOrderLocal,
      submitValidatedOrderLocal,
      getOrderIcon,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteOrderAlert (order) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theOrder') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeOrderLocal(order)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>