<template>
  <div class="row">
    <b-col md="4">
      <div class="d-flex justify-content-between">
        <div
            v-if="sectionIndex==0"
            class="w-25"
        >
          {{ capitalize($t('if')) }}
        </div>
        <div
            v-else
            class="w-25"
        >
          <select2
              :options="sectionLogicOperatorList"
              reduce="value"
              :displayLabel="false"
              size="sm"
              :model.sync="filter.sectionLogicOperator"
              :clearable="false"
              label="i18n"
              :placeholder="capitalize($t('operator'))"
              :disabled="('_sectionOperatorDisabled' in filter.sectionFilters[0]) && filter.sectionFilters[0]._sectionOperatorDisabled==true"
          />
        </div>

        <select2
            :options="localAvailableFilters"
            :displayLabel="false"
            size="sm"
            :model.sync="filter.sectionFilters[0].field"
            :clearable="false"
            :placeholder="capitalize($t('field'))"
            :disabled="('_fieldDisabled' in filter.sectionFilters[0]) && filter.sectionFilters[0]._fieldDisabled==true"
            class="w-75 pl-50"
            reduce="field"
            label="i18n"

            @input="performSelectedFilter"
        />
      </div>
    </b-col>

    <b-col md="8">
      <div class="d-flex justify-content-between">
        <div class="w-100">
          <transition name="fade">
            <filter-detail-text
                v-if="fieldType == 'text'"
                :section-filter.sync="filter.sectionFilters[0]"
                :is-disabled="filter.sectionFilters[0]._fieldDisabled != null && filter.sectionFilters[0]._fieldDisabled==true"
            />
          </transition>

          <transition name="fade">
            <filter-detail-date
                v-if="fieldType == 'date'"
                :section-filter.sync="filter.sectionFilters[0]"
            />
          </transition>

          <transition name="fade">
            <filter-detail-number
                v-if="fieldType == 'number'"
                :section-filter.sync="filter.sectionFilters[0]"
                :is-disabled="filter.sectionFilters[0]._fieldDisabled != null && filter.sectionFilters[0]._fieldDisabled==true"
            />
          </transition>

          <transition name="fade">
            <filter-detail-boolean
                v-if="fieldType == 'boolean'"
                :section-filter.sync="filter.sectionFilters[0]"
                :corresponding-available-filter="correspondingAvailableFilter"
                :is-disabled="filter.sectionFilters[0]._fieldDisabled != null && filter.sectionFilters[0]._fieldDisabled==true"
            />
          </transition>

          <transition name="fade">
            <filter-detail-select
                v-if="fieldType == 'select' && !('isSpecific' in correspondingAvailableFilter)"
                :section-filter.sync="filter.sectionFilters[0]"
                :corresponding-available-filter="correspondingAvailableFilter"
                :is-disabled="filter.sectionFilters[0]._fieldDisabled != null && filter.sectionFilters[0]._fieldDisabled==true"
            />
          </transition>

          <transition name="fade">
            <filter-detail-autocomplete-select
                v-if="fieldType == 'autocompleteSelect' && !('isSpecific' in correspondingAvailableFilter)"
                :section-filter.sync="filter.sectionFilters[0]"
                :corresponding-available-filter="correspondingAvailableFilter"
                :is-disabled="filter.sectionFilters[0]._fieldDisabled != null && filter.sectionFilters[0]._fieldDisabled==true"
            />
          </transition>

          <transition name="fade">
            <filter-detail-employee
                v-if="fieldType == 'employee' && !('isSpecific' in correspondingAvailableFilter)"
                :section-filter.sync="filter.sectionFilters[0]"
            />
          </transition>

          <transition name="fade">
            <filter-detail-specific-select
                v-if="correspondingAvailableFilter && correspondingAvailableFilter.isSpecific && fieldType == 'select'"
                :section-filter.sync="filter.sectionFilters[0]"
                :corresponding-available-filter="correspondingAvailableFilter"
            />
          </transition>

          <transition name="fade">
            <filter-detail-specific-work-flo-status-date
                v-if="correspondingAvailableFilter && correspondingAvailableFilter.isSpecific && fieldType == 'workFlo_status_date'"
                :section-filter.sync="filter.sectionFilters[0]"
            />
          </transition>
        </div>

        <div
            class="ml-2 text-right text-danger cursor-pointer"
            style="padding-top:2px"

            @click="$emit('removeFilter')"
        >
          <button-remove
              size="sm"
              :is-disabled="filter.sectionFilters[0]._allowDelete != null && filter.sectionFilters[0]._allowDelete==false"
              :with-icon="true"
              :with-text="false"
          />
        </div>
      </div>

    </b-col>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import i18n from '../../../../libs/i18n'

import select2 from '../../../input/Select2.vue'
import FilterDetailText from './_FilterDetailText.vue'
import FilterDetailDate from './_FilterDetailDate.vue'
import FilterDetailNumber from './_FilterDetailNumber.vue'
import FilterDetailBoolean from './_FilterDetailBoolean.vue'
import FilterDetailSelect from './_FilterDetailSelect.vue'
import FilterDetailAutocompleteSelect from './_FilterDetailAutocompleteSelect.vue'
import FilterDetailEmployee from './_FilterDetailEmployee.vue'
import FilterDetailSpecificSelect from './_FilterDetailSpecificSelect.vue'
import FilterDetailSpecificWorkFloStatusDate from './_FilterDetailSpecificWorkFloSatusDate.vue'
import ButtonRemove from '../../../button/Remove.vue'

export default {
  components: {
    FilterDetailText,
    FilterDetailDate,
    FilterDetailNumber,
    FilterDetailBoolean,
    FilterDetailSelect,
    FilterDetailEmployee,
    FilterDetailAutocompleteSelect,
    FilterDetailSpecificSelect,
    FilterDetailSpecificWorkFloStatusDate,
    select2,
    ButtonRemove
  },
  props: {
    filter: {
      type: Object,
      default: () => {}
    },
    sectionIndex: {
      type: Number,
      default: 0
    },
    availableFilters: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // console.log(JSON.parse(JSON.stringify(props.filter)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sectionLogicOperatorList = ref([{
      value: 'AND',
      i18n: i18n.t('AND')
    }, {
      value: 'OR',
      i18n: i18n.t('OR')
    }])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const fieldType = computed(() => {
      if (props.filter.sectionFilters[0].field != null) {
        return props.availableFilters.find(af => af.field == props.filter.sectionFilters[0].field).type
      } else {
        return null
      }
    })

    const correspondingAvailableFilter = computed(() => {
      if (props.filter.sectionFilters[0].field != null) {
        return props.availableFilters.find(af => af.field == props.filter.sectionFilters[0].field)
      } else {
        return null
      }
    })

    const localAvailableFilters = computed(() => {
      return props.availableFilters.filter(af=>!('show' in af) || af.show == "true")
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(() => props.filter, val => {
    //   console.log(val)
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const performSelectedFilter = () => {
      populateSubFields()

      if (correspondingAvailableFilter.value.isSpecific) {
        props.filter.sectionFilters[0].name = correspondingAvailableFilter.value.name
        props.filter.sectionFilters[0].value = correspondingAvailableFilter.value.value
        delete props.filter.sectionFilters[0].operator
      } else {
        delete props.filter.sectionFilters[0].name
        props.filter.sectionFilters[0].value = ''
      }
    }

    const populateSubFields = () => {
      props.filter.sectionFilters[0].subFields = correspondingAvailableFilter.value.subFields
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      sectionLogicOperatorList,

      // Computed
      fieldType,
      correspondingAvailableFilter,
      localAvailableFilters,

      // Methods
      performSelectedFilter,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>