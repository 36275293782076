<template>
  <b-row>
    <b-col sm="12">
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-2">
          <select2
              :model.sync="sort.column"
              :options="availableSorts"
              :clearable="false"
              reduce="field"
              label="i18n"
              size="sm"
              name="sort"
              :display-label="false"
          />
        </div>

        <div class="w-100 mr-2">
          <select2
              :model.sync="sort.order"
              :options="availableOrders"
              reduce="value"
              label="display"
              size="sm"
          />
        </div>

        <div
            style="padding-top: 2px;"
            @click="$emit('removeSort')"
        >
          <button-remove
              size="sm"
              :with-icon="true"
              :with-text="false"
          />
        </div>
      </div>
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import i18n from '../../../../libs/i18n'

import Select2 from '../../../input/Select2.vue'
import ButtonRemove from '../../../button/Remove.vue'

export default {
  components: { ButtonRemove, Select2 },
  props: {
    sort: {
      type: Object,
      default: () => {}
    },
    sortIndex: {
      type: Number,
      default: 0
    },
    availableSorts: {
      type: Array,
      default: () => []
    }
    },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const availableOrders = ref([
      {
        value: 'DESC',
        display: capitalize(i18n.t('descendingOrder'))
      },
      {
        value: 'ASC',
        display: capitalize(i18n.t('ascendingOrder'))
      }]
    )

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      availableOrders,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>