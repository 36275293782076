<template>
  <div>


    <label>{{ capitalize($t(name)) }}</label>
    <div class="border rounded px-1 pt-1 pb-50">
      <draggable
          tag="ul"
          group="all-groups"
          class="draggable-list pl-0 mb-0"
          ghost-class="moving-card"
          filter=".action-button"
          :list="modelLocal"
          :animation="200"
      >
        <li
            v-for="(item, index) in modelLocal"
            :key="'listOrder_'+name+'_'+index"
            class="p-50 mb-1 d-flex justify-content-between align-items-center bg-white border border-white shadow rounded-lg cursor-move"
        >
          <slot name="itemView"
                :item="item"
                :index="index">
            🆕
          </slot>
        </li>

      </draggable>


      <div class="d-flex">
        <icon icon="plus" size="lg" class="text-success my-auto mx-1"/>
        <select2
            class="mt-1"
            :options="listLocal"
            :label="label"
            :clear-after-choice="true"
            :placeholder="placeholder"

            @input="addToModel"
        />
      </div>
    </div>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import draggable from 'vuedraggable'

import Select2 from './Select2.vue'

export default {
  components: {
    Select2,
    draggable
  },
  props: {
    model: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    name: {
      type: String
    },
    label: {
      type: String,
      default: '_display'
    },
    placeholder: {
      type: String,
      default: 'add'
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const listLocal = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const modelLocal = computed({
      get () {
        return props.model
      }, set (val) {
        emit('update:model', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.list, val => {
      updateListLocal()
    })

    watch(modelLocal, val => {
      console.log(val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateListLocal = () => {
      // console.log(props.list)

      listLocal.value = JSON.parse(JSON.stringify(props.list)).filter(pl => {
        // console.log(modelLocal.value.some(ml => JSON.stringify(ml) == JSON.stringify(pl)))
        return !modelLocal.value.some(ml => JSON.stringify(ml) == JSON.stringify(pl))
      })

      // console.log(listLocal.value)
      // console.log(modelLocal.value)
    }

    const addToModel = (selectedListItem) => {
      console.log(selectedListItem)
      if (selectedListItem != null) {
        modelLocal.value.push(selectedListItem)
        updateListLocal()
      }

    }

    const removeToModel = (index) => {
      console.log(index)
      console.log(modelLocal.value[index])

      modelLocal.value.splice(index, 1)
      updateListLocal()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    updateListLocal()

    return {
      // Components
      capitalize,

      // Data
      listLocal,

      // Computed
      modelLocal,

      // Methods
      addToModel,
      removeToModel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.draggable-list {
  min-height: 100px;
}

/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.moving-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
</style>