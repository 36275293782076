<template>
  <div>

    <div
        ref="formProducts"
        class="repeater-form"
        :style="{height: trHeight}"
        :key="componentKey"
    >
      <b-row
          v-for="(product, index) in products"
          :key="index"
          ref="row"
          class="pb-2"
      >
        <product
            :product="product"
            :index="index"
            :allowRemove="allowRemove(index)"
            :disable-t-v-a="disableTVA"
            @changePreTaxAmount="$emit('changePreTaxAmount')"
            @removeProduct="removeProduct(index)"
            @updateSize="initTrHeight"
        />

      </b-row>
    </div>

    <button-add
        size="sm"
        @click="addNewProduct"
        :text="$t('AddProduct')"
    />

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { heightTransition } from '@/utils/form'
import Ripple from 'vue-ripple-directive'
import Product from './components/_product'
import store from '@/store'
import ButtonAdd from '@/components/button/Add'

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    Product,
    ButtonAdd
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    disableTVA: {
      type: Boolean,
      default: false
    }
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted( () => {
      emit('isProductMounted')
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      currentBreakPoint,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    addNewProduct () {
      this.products.push({
        vatRate: 1,
        quantity: 1,
        isDiscountPercent: false,
        preTaxAmount: 0,
        vat: 0,
        totalAmount: 0
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeProduct (index) {
      if (this.allowRemove(index)) {
        this.products.splice(index, 1)
        this.componentKey++

        this.$nextTick(() => {
          if (typeof this.$refs.row[0] !== 'undefined') {
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
          } else {
            this.initTrHeight()
          }

          this.$emit('changePreTaxAmount')
        })
      }
    },
    allowRemove (index) {
      return this.allowEmpty || (!this.allowEmpty && this.products.length >1)
    },
    initTrHeight () {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formProducts.scrollHeight)
      })
    },
  },
  mounted () {
    this.initTrHeight()

    this.$nextTick(() => {
      if (!this.allowEmpty && this.products.length == 0) {
        this.products.push({
          vatRate: 1,
          quantity: 1,
          isDiscountPercent: false,
          preTaxAmount: 0,
          vat: 0,
          totalAmount: 0
        })

        this.$nextTick(() => {
          if (['md', 'lg', 'xl'].includes(this.currentBreakPoint)) {
            this.trSetHeight(this.products.length * 135.656)
          } else {
            this.trSetHeight(this.products.length * 800.906)
          }
        })
      }

      if (this.products.length) {
        this.$nextTick(() => {
          if (['md', 'lg', 'xl'].includes(this.currentBreakPoint)) {
            this.trSetHeight(this.products.length * 135.656)
          } else {
            this.trSetHeight(this.products.length * 800.906)
          }
        })
      }

    })
  },
  created () {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss"
       scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>