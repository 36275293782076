<template>
  <div class="col-md-12">
    <b-card class="baseHeaderSortDetails">

      <div class="w-100 d-flex justify-content-between">
        <div class="w-100"><h2>{{ capitalize($t('vues')) }}</h2></div>
        <div>
          <button-add
              @click="$emit('addModuleView')"
              :withIcon="true"
              size="sm"
          />
        </div>
      </div>

      <b-table
          responsive
          :items="modulesViews"
          :fields="columns"
          striped
          hover
          @row-clicked="$emit('selectItem', $event)"
          :key="componentKey"
      >
        <template #head(name)="{field}">
          {{ field.display }}
        </template>
        <template #cell(type)="{item}">
          {{ capitalize($t(item.type)) }}
        </template>
        <template #cell(actions)="{item}">
          <check-button
              v-if="!isModuleViewDisabled(item)"
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              size="sm"

              @click="$emit('setDefaultModuleView', {moduleView:item, isDefault: true})"
          />
          <stop-button
              v-else
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              size="sm"

              @click="$emit('setDefaultModuleView', {moduleView:item, isDefault: false})"
          />
          <edit-button
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              size="sm"

              @click="$emit('editModuleView', item)"
          />
          <duplicate-button
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              size="sm"

              @click="$emit('deplicateModuleView', item)"
          />
          <delete-button
              :with-text="false"
              :with-icon="true"
              :withBorder="false"
              size="sm"

              @click="$emit('deleteModuleView', item)"
          />

        </template>


      </b-table>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'
import { getUserData } from '../../../utils/utils'

import EditButton from '../../button/Edit.vue'
import DuplicateButton from '../../button/Duplicate.vue'
import DeleteButton from '../../button/Delete.vue'
import i18n from '../../../libs/i18n'
import store from '../../../store'
import ButtonAdd from '../../button/Add.vue'
import CheckButton from '../../button/Check.vue'
import StopButton from '../../button/Stop.vue'

export default {
  components: {
    ButtonAdd,
    EditButton,
    DuplicateButton,
    DeleteButton,
    CheckButton,
    StopButton
  },
  props: {
    module: {
      type: String,
      default: ''
    },
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      { key: 'name', 'sortable': true, display: capitalize(i18n.t('name')) },
      { key: 'type', 'sortable': true, display: capitalize(i18n.t('type')) },
      { key: 'actions', display: i18n.tc('action', 2) }
    ])
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const modulesViews = computed(() => {
      return store.getters['moduleView/get' + capitalize(props.module) + 'ModuleViews'].filter(mv => mv._createdBy != null)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const isModuleViewDisabled = (moduleView) => {
      if (moduleView.id == getUserData().defaultViews[props.module].id) {
        return true
      } else {
        return false
      }
    }

    const update = () => {
      emit('resetFilters')
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      columns,
      modulesViews,
      componentKey,

      // Computed

      // Methods
      isModuleViewDisabled,
      update,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>