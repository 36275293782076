<template>
  <div>
    <b-row>

      <!--      Name-->
      <b-col md="9">
        <field-input
            :model.sync="moduleViewLocal.name"
            name="name"
            rules="required"
        />
      </b-col>

      <!--      Default-->
      <b-col md="3">
        <!--        <field-switch-->
        <!--            :model.sync="moduleViewLocal.isDefault"-->
        <!--            name="byDefault"-->
        <!--        />-->
      </b-col>

      <!--      Module-->
      <b-col md="6">
        <field-select
            name="module"
            :model.sync="moduleViewLocal.module"
            :options="moduleList"
            label="display"
            reduce="module"
            :disabled="true"
            :clearable="false"
        />
      </b-col>

      <!--      Type-->
      <b-col md="6">
        <field-select
            name="viewType"
            :model.sync="moduleViewLocal.type"
            :options="viewTypeList"
            label="display"
            reduce="viewType"

            @input="moduleViewLocal.listColumns=[]; moduleViewLocal.kanbanSortBy=null; moduleViewLocal.kanbanColumnAmount=null; componentKey++"
        />
      </b-col>

      <b-col
          v-if="moduleViewLocal.type == 'list'"
          md="12"
      >
        <field-select
            name="globalAmount"
            :model.sync="moduleViewLocal.listGlobalAmount"
            :options="availableGlobalAmounts"
            label="i18n"
            reduce="value"
        />

      </b-col>


      <b-col
          md="12"
          class="mt-1"
      >

        <b-tabs fill>

          <!--          View-->
          <b-tab :key="componentKey">
            <template #title>
              <icon icon="eye"/>
              <span>{{ capitalize($t('view')) }}</span>
            </template>

            <!--            List-->
            <field-list-order
                v-if="moduleViewLocal.type == 'list'"
                name="columns"
                :placeholder="capitalize($t('addField'))"
                :model.sync="moduleViewLocal.listColumns"
                :list="viewTypeListColumns"
                label="title"
                class="mb-2"
                ref="fieldListOrder"
            >
              <template #itemView="{item, index}">
                <div class="w-100 d-flex justify-content-between">
                  <icon
                      icon="bars"
                      class="my-auto mx-1"
                  />
                  <div class="w-100 my-auto">{{ capitalize($t(item.title)) }}</div>
                  <button-delete
                      class="mx-1"
                      :with-icon="true"
                      :with-text="false"
                      size="sm"

                      @click="$refs.fieldListOrder.removeToModel(index)"
                  />
                </div>

              </template>
            </field-list-order>

            <!--            Kanban-->
            <b-row
                v-if="moduleViewLocal.type == 'kanban'"
            >
              <b-col md="6">
                <field-select
                    name="classifyBy"
                    :model.sync="moduleViewLocal.kanbanSortBy"
                    :options="listKanbanSortBy"
                    label="display"
                    reduce="sortBy"
                    :is-required="true"
                />
              </b-col>
              <b-col md="6">
                <field-select
                    name="aggregateBy"
                    :model.sync="moduleViewLocal.kanbanColumnAmount"
                    :options="kanbanColumnAmountList"
                    label="display"
                    reduce="value"
                />
              </b-col>
            </b-row>


          </b-tab>

          <!--          Filters-->
          <b-tab>
            <template #title>
              <icon icon="filter"/>
              <span>{{ capitalize($tc('filter', 2)) }}</span>
            </template>

            <filter-details
                :available-filters="availableFilters"
                :filters.sync="moduleViewLocal.filters"
            />


          </b-tab>

          <!--          Sorts-->
          <b-tab>
            <template #title>
              <icon icon="arrows-alt-v"/>
              <span>{{ capitalize($t('sort')) }} ({{ moduleViewLocal.sorts.length }})</span>
            </template>

            <sort-details
                :available-sorts="availableSorts"
                :sorts.sync="moduleViewLocal.sorts"
            />

          </b-tab>

        </b-tabs>


      </b-col>

    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../utils/filter'
import { isEmptyObject }        from '../../utils/utils'

import i18n from '../../libs/i18n'

import FieldInput     from '../input/Input.vue'
import FieldSwitch    from '../input/Switch.vue'
import FieldSelect    from '../input/Select2.vue'
import FieldListOrder from '../input/ListOrder.vue'
import ButtonDelete   from '../button/Delete.vue'
import FilterDetails  from '../base3/header/FilterDetails.vue'
import SortDetails    from '../base3/header/SortDetails.vue'
import store          from '../../store'

export default {
  components: {
    FieldInput,
    FieldSwitch,
    FieldSelect,
    FieldListOrder,
    ButtonDelete,
    FilterDetails,
    SortDetails
  },
  props: {
    moduleView: {
      type: Object,
      default: () => {}
    },
    availableFilters: {
      type: Array,
      default: () => []
    },
    availableSorts: {
      type: Array,
      default: () => []
    },
    availableGlobalAmounts: {
      type: Array,
      default: () => []
    }

  },
  setup (props, { emit }) {
    // console.log(JSON.parse(JSON.stringify(props.moduleView)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const moduleList = ref([
      {
        module: props.moduleView.module,
        display: capitalize(i18n.t(props.moduleView.module))
      }
    ])
    const viewTypeList = ref([
      {
        viewType: 'list',
        display: capitalize(i18n.t('list'))
      },
      {
        viewType: 'kanban',
        display: capitalize(i18n.t('kanban'))
      },
    ])
    const fieldListOrder = ref()
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const moduleViewLocal = computed({
      get () {
        return props.moduleView
      },
      set (val) {
        emit('update:moduleView', val)
      }
    })

    const viewTypeListColumns = computed(() => {
      let module = moduleViewLocal.value.module
      if (moduleViewLocal.value.module == 'crm') {
        module = 'workFlo'
      } else if (moduleViewLocal.value.module == 'supplierInvoices') {
        module = 'incomingInvoice2'
      } else if (moduleViewLocal.value.module == 'customerInvoices') {
        module = 'outgoingInvoice2'
      } else if (moduleViewLocal.value.module == 'offers') {
        module = 'offer2'
      }
      module = module.replace(/s$/, '')

      // console.log(JSON.parse(JSON.stringify(store.getters[module + '/getAvailableListColumns'])))

      return store.getters[module + '/getAvailableListColumns']
    })

    const listKanbanSortBy = computed(() => {
      let listKanbanSortBy = []
      props.availableFilters.forEach(af => {
        if (af.subFields.length) {
          let subField = af.subFields[0]
          if (af.field == "status") {
            subField = "name"
          }

          listKanbanSortBy.push({
            display: capitalize(i18n.t(af.field)),
            sortBy: af.field + '_' + subField
          })
        } else {
          listKanbanSortBy.push({
            display: capitalize(i18n.t(af.field)),
            sortBy: af.field
          })
        }
      })

      return listKanbanSortBy
    })

    const kanbanColumnAmountList = computed(() => {
      let kanbanColumnAmountList = []

      props.availableFilters.forEach(af => {
        if (af.type == 'number') {
          kanbanColumnAmountList.push({
            display: capitalize(i18n.t(af.field)),
            value: af.field
          })
        }
      })

      return kanbanColumnAmountList
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      capitalize,

      // Data
      moduleList,
      viewTypeList,
      fieldListOrder,
      componentKey,

      // Computed
      moduleViewLocal,
      viewTypeListColumns,
      listKanbanSortBy,
      kanbanColumnAmountList,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>