var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('b-card',{staticClass:"baseHeaderSortDetails"},[_c('div',{staticClass:"w-100 d-flex justify-content-between"},[_c('div',{staticClass:"w-100"},[_c('h2',[_vm._v(_vm._s(_vm.capitalize(_vm.$t('vues'))))])]),_c('div',[_c('button-add',{attrs:{"withIcon":true,"size":"sm"},on:{"click":function($event){return _vm.$emit('addModuleView')}}})],1)]),_c('b-table',{key:_vm.componentKey,attrs:{"responsive":"","items":_vm.myConcernedViews,"fields":_vm.columns,"striped":"","hover":""},on:{"row-clicked":function($event){return _vm.$emit('selectItem', $event)}},scopedSlots:_vm._u([{key:"head(name)",fn:function(ref){
var field = ref.field;
return [_vm._v(" "+_vm._s(field.display)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item._createdBy == null)?_c('span',[_vm._v(" "+_vm._s(_vm.capitalize(_vm.$t(item.type)) + " " + _vm.$t('byDefault'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.capitalize(_vm.$t(item.name)))+" ")])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalize(_vm.$t(item.type)))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(!_vm.isCurrentView(item))?_c('check-button',{attrs:{"with-text":false,"with-icon":true,"withBorder":false,"size":"sm"},on:{"click":function($event){return _vm.$emit('setDefaultModuleView', {moduleView:item})}}}):_c('stop-button',{attrs:{"with-text":false,"with-icon":true,"withBorder":false,"is-disabled":true,"size":"sm"}}),_c('edit-button',{attrs:{"with-text":false,"with-icon":true,"withBorder":false,"disabled":_vm.isModuleViewDisabled(item),"size":"sm"},on:{"click":function($event){return _vm.$emit('editModuleView', item)}}}),_c('duplicate-button',{attrs:{"with-text":false,"with-icon":true,"withBorder":false,"size":"sm"},on:{"click":function($event){return _vm.$emit('deplicateModuleView', item)}}}),_c('delete-button',{attrs:{"with-text":false,"with-icon":true,"withBorder":false,"disabled":_vm.isModuleViewDisabled(item),"size":"sm"},on:{"click":function($event){return _vm.$emit('deleteModuleView', item)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }