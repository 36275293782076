<template>
  <div class="col-md-12">
    <b-card class="baseHeaderSortDetails">
      <div
          v-for="(sort, indexSort) in localSorts"
          :key="'sort_'+indexSort"
          class="pb-2"
      >
        <sort-detail
            :sort.sync="sort"
            :sort-index="indexSort"
            :available-sorts="availableSorts"

            @removeSort="removeSort(indexSort)"
        />
      </div>


      <button-add
          size="sm"
          @click="addNewSort"
          :text="capitalize($t('addSort'))"
      />
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import ButtonAdd from '../../../components/button/Add'
import SortDetail from './components/_SortDetail.vue'

export default {
  components: {
    SortDetail,
    ButtonAdd
  },
  props: {
    sorts: {
      type: Array,
      default: () => []
    },
    availableSorts: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // console.log(props.availableSorts)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localSorts = ref(JSON.parse(JSON.stringify(props.sorts)))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const enabledSorts = computed(() => {
      let output = []

      localSorts.value.forEach((s, sortIndex) => {
        if (
            s.column != null &&
            s.order != null
        ) {
          output.push(s)
        }
      })

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(enabledSorts, val => {
      // console.log(val)
      if (JSON.stringify(val) != JSON.stringify(props.sorts)) {
        // console.log('différents !')
        emit('update:sorts', val)
      }
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addNewSort = () => {
      localSorts.value.push({
        column: null,
        order: 'DESC'
      })
    }

    const removeSort = (indexSort) => {
      localSorts.value.splice(indexSort, 1)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      localSorts,

      // Computed

      // Methods
      addNewSort,
      removeSort
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>