<template>
  <div class="row">

    <div class="col-md-3">
      <select2
          :options="autocompleteWorkFloStatus"
          label="name"
          reduce="id"
          :displayLabel="false"
          size="sm"
          :model.sync="selectedWorkFloStatusLocal"
          :disabled="isSelectedValueDisabled"
      />
    </div>

    <div class="col-md-3">
      <select2
          :options="operatorList"
          :displayLabel="false"
          size="sm"
          :model.sync="operatorLocal"
          :clearable="false"
          :disabled="isDisabled"
          label="i18n"
      />
    </div>

    <div class="col-md-6">
      <b-row>
        <b-col :md="operatorLocal && operatorLocal.value=='between'?6:12">
          <date-field
              :model.sync="inputValueLocal1"
              size="sm"
              class="my-auto flatPickrSmSize"
              :disabled="isSelectedValueDisabled"
          />
        </b-col>
        <b-col
            v-if="operatorLocal && operatorLocal.value=='between'"
            md="6"
        >
          <date-field
              v-if="operatorLocal.value == 'between'"
              :model.sync="inputValueLocal2"
              :min-date="inputValueLocal1"
              size="sm"
              class="my-auto flatPickrSmSize"
              :disabled="isSelectedValueDisabled"
          />
        </b-col>

      </b-row>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import select2 from '../../../input/Select2.vue'
import { capitalize } from '../../../../utils/filter'
import i18n from '../../../../libs/i18n'
import useAPI from '../../../../utils/useAPI'
import DateField from '../../../input/Date.vue'

export default {
  components: { DateField, select2 },
  props: {
    sectionFilter: {
      type: Object,
      default: () => {}
    },
    correspondingAvailableFilter: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const operatorLocal = ref({
      value: props.sectionFilter.operator,
      i18n: capitalize(i18n.t(props.sectionFilter.operator))
    })

    const operatorList = ref([
      {
        value: 'equal',
        i18n: capitalize(i18n.t('equal'))
      },
      {
        value: 'not_equal',
        i18n: capitalize(i18n.t('not_equal'))
      },
      {
        value: 'before',
        i18n: capitalize(i18n.t('before'))
      },
      {
        value: 'after',
        i18n: capitalize(i18n.t('after'))
      },
      {
        value: 'between',
        i18n: capitalize(i18n.t('between'))
      },
      {
        value: 'is_empty',
        i18n: capitalize(i18n.t('is_empty'))
      },
      {
        value: 'is_not_empty',
        i18n: capitalize(i18n.t('is_not_empty'))
      },
    ])

    const selectedWorkFloStatusLocal = ref(props.sectionFilter.subFields[0])

    const inputValueLocal1 = ref(null)
    const inputValueLocal2 = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {autocompleteWorkFloStatus} = useAPI()

    const isSelectedValueDisabled = computed(() => {
      return props.isDisabled
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedWorkFloStatusLocal, val => {
      parentUpdate()
    })

    watch(operatorLocal, val => {
      if (
          val != null &&
          (
              val.value == 'is_empty' ||
              val.value == 'is_not_empty'
          )) {
        inputValueLocal1.value = null
        inputValueLocal2.value = null
      }

      parentUpdate()
    })

    watch(inputValueLocal1, val => {
      parentUpdate()
    })
    watch(inputValueLocal2, val => {
      parentUpdate()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const parentUpdate = () => {
      if (!props.isDisabled && operatorLocal.value) {
        if (['is_empty', 'is_not_empty'].includes(operatorLocal.value.value)) {
          emit('update:sectionFilter', {
            logicOperator: props.sectionFilter.logicOperator,
            name: props.sectionFilter.name,
            field: props.sectionFilter.field,
            operator: operatorLocal.value.value,
            subFields: props.sectionFilter.subFields,
            value: selectedWorkFloStatusLocal.value
          })
        } else if (
            operatorLocal.value.value == 'between'
        ) {
          if (
              inputValueLocal1.value != null &&
              inputValueLocal2.value != null) {
            emit('update:sectionFilter', {
              logicOperator: props.sectionFilter.logicOperator,
              name: props.sectionFilter.name,
              field: props.sectionFilter.field,
              operator: operatorLocal.value.value,
              subFields: props.sectionFilter.subFields,
              value: [selectedWorkFloStatusLocal.value, inputValueLocal1.value, inputValueLocal2.value]
            })
          }
        } else {
          if (inputValueLocal1.value != null) {
            emit('update:sectionFilter', {
              logicOperator: props.sectionFilter.logicOperator,
              name: props.sectionFilter.name,
              field: props.sectionFilter.field,
              operator: operatorLocal.value.value,
              subFields: props.sectionFilter.subFields,
              value: [selectedWorkFloStatusLocal.value, inputValueLocal1.value]
            })
          }
        }
      }

    }

    const initPopulate = () => {
      if (
          typeof props.sectionFilter.value === 'object' &&
          Array.isArray(typeof props.sectionFilter.value) &&
          props.sectionFilter.value.length == 2
      ) {
        inputValueLocal1.value = props.sectionFilter.value[0]
        inputValueLocal2.value = props.sectionFilter.value[1]
      } else {
        inputValueLocal1.value = props.sectionFilter.value
        inputValueLocal2.value = props.sectionFilter.value
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    initPopulate()

    return {
      // Components

      // Data
      operatorLocal,
      operatorList,
      selectedWorkFloStatusLocal,
      inputValueLocal1,
      inputValueLocal2,

      // Computed
      autocompleteWorkFloStatus,
      isSelectedValueDisabled,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>