<template>
  <div class="row">

    <div class="col-md-4">
      <select2
          :options="operatorList"
          :displayLabel="false"
          size="sm"
          :model.sync="operatorLocal"
          :clearable="false"
          :disabled="isDisabled"
          label="i18n"
      />
    </div>

    <div class="col-md-8">
      <input-field
          :model.sync="inputValueLocal"
          size="sm"
          class="my-auto"
          :disabled="isInputValueDisabled"

          @input="inputValueUpdated"
      />
    </div>

  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import select2 from '../../../input/Select2.vue'
import InputField from '../../../input/Input.vue'
import i18n from '../../../../libs/i18n'

export default {
  components: {
    select2,
    InputField
  },
  props: {
    sectionFilter: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const operatorLocal = ref({
      value: props.sectionFilter.operator,
      i18n: capitalize(i18n.t(props.sectionFilter.operator))
    })

    const operatorList = ref([
      {
        value: 'equal',
        i18n: capitalize(i18n.t('equal'))
      },
      {
        value: 'not_equal',
        i18n: capitalize(i18n.t('not_equal'))
      },
      {
        value: 'contains',
        i18n: capitalize(i18n.t('contains'))
      },
      {
        value: 'not_contains',
        i18n: capitalize(i18n.t('not_contains'))
      },
      {
        value: 'starts_with',
        i18n: capitalize(i18n.t('starts_with'))
      },
      {
        value: 'ends_with',
        i18n: capitalize(i18n.t('ends_with'))
      },
      {
        value: 'is_empty',
        i18n: capitalize(i18n.t('is_empty'))
      },
      {
        value: 'is_not_empty',
        i18n: capitalize(i18n.t('is_not_empty'))
      },
    ])

    const inputValueLocal = ref(props.sectionFilter.value)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isInputValueDisabled = computed(() => {
      if (
          props.isDisabled ||
          (
              operatorLocal.value != null &&
              (
                  operatorLocal.value.value == 'is_empty' ||
                  operatorLocal.value.value == 'is_not_empty'
              )
          )
      ) {
        return true
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(operatorLocal, val => {
      if (
          val != null &&
          (
              val.value == 'is_empty' ||
              val.value == 'is_not_empty'
          )) {
        inputValueLocal.value = null
      }

      parentUpdate()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const parentUpdate = () => {
      if (!props.isDisabled) {
          emit('update:sectionFilter', {
            name: props.sectionFilter.name,
            logicOperator: props.sectionFilter.logicOperator,
            field: props.sectionFilter.field,
            operator: operatorLocal.value.value,
            subFields: props.sectionFilter.subFields,
            value: inputValueLocal.value
          })
      }
    }

    let delayTimer
    const inputValueUpdated = () => {
        clearTimeout(delayTimer)
        delayTimer = setTimeout(() => {
          parentUpdate()
        }, 500)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      operatorLocal,
      operatorList,
      inputValueLocal,

      // Computed
      isInputValueDisabled,

      // Methods
      inputValueUpdated,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>