<template>

  <b-form-group
      :label-for="name"
      :label="displayLabel?(capitalize($t(name)) + (localIsDiscountPercent?' (%)':' (€)')):''"
  >

    <b-input-group>
      <b-input-group-prepend is-text>

        <b-form-checkbox
            v-model="localIsDiscountPercent"
            @input="$emit('input', $event)"
            class="custom-control-primary"
            :disabled="disabled"
            switch
        >
          <span class="switch-icon-left">
            <icon icon="percent"/>
          </span>
          <span class="switch-icon-right">
            <icon icon="euro-sign"/>
          </span>
        </b-form-checkbox>

      </b-input-group-prepend>
      <b-form-input
          :id="name"
          v-model="localDiscount"
          :placeholder="capitalize($t(name))"
          @input="$emit('input', $event)"
          :disabled="disabled"
      />
    </b-input-group>
  </b-form-group>

</template>

<script>
import { capitalize } from '@/utils/filter'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    discount: {
      required: true
    },
    isDiscountPercent: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localDiscount = ref(props.discount)
    const localIsDiscountPercent = ref(props.isDiscountPercent)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localDiscount, () => {
      emit('update:discount', localDiscount.value)
    })
    watch(localIsDiscountPercent, () => {
      emit('update:isDiscountPercent', localIsDiscountPercent.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      localDiscount,
      localIsDiscountPercent,

      // Computed

      // Methods

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss"
       scoped>
.input-group-text {
  padding: 0.43rem 1rem;
}
</style>