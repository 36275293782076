<template>
  <b-row>
    <!--          Customer request number-->
    <b-col cols="12">
      <field-input
          :name="'signedDocumentNumber'"
          :model.sync="offer.signedDocumentNumber"
      />
    </b-col>

    <!--          Customer request number-->
    <b-col cols="12">
      <field-date
          :name="'signatureDate'"
          :isRequired="true"
          :model.sync="offer.signedDocumentDate"
      />
    </b-col>

    <!--          Customer request number-->
    <b-col cols="12"
           class="mb-1">
      <field-file
          :singleFile.sync="offer.signedDocument"
          :documentType="type"
          :listDisplay="listDisplay"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldInput from '@/components/input/Input'
import FieldDate from '@/components/input/Date'
import FieldFile from '@/components/input/File'

export default {
  components: {
    FieldInput,
    FieldDate,
    FieldFile
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    },
    listDisplay: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'offer'
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>