<template>
  <div>

    <div class="d-flex justify-content-between">

<!--      <field-select-->
<!--          :name="'article'+ (listIndex != -1?(' '+(listIndex+1)):'')"-->
<!--          :displayLabel="displayLabel"-->
<!--          :model.sync="articleLocal"-->
<!--          :options="autocompleteArticles"-->
<!--          :label="'name'"-->
<!--          :isRequired="true"-->
<!--          :allowAdd="true"-->
<!--          :addText="'AddArticle'"-->
<!--          @addCallback="selectArticle"-->
<!--          class="w-100"-->
<!--          :key="componentKey"-->
<!--          :disabled="disabled"-->
<!--      >-->
<!--      </field-select>-->

      <field-select
        :name="'article'+ (listIndex != -1?(' '+(listIndex+1)):'')"
        :display-label="displayLabel"
        :model.sync="articleLocal"
        :options="autocompleteArticles"
        :is-required="true"
        :allow-add="true"
        :disabled="disabled"
        :get-detailed-object="true"
        dispatch-url="article/getArticle"
        label="name"
        add-text="AddArticle"

        @addCallback="selectArticle"
        />

      <div
          :style="['xs', 'sm'].includes(currentBreakPoint)?'margin-top: 25px':false"
          class="mt-2 ml-1"
      >
        <button-external-link
            :withBorder="false"
            @click="selectArticle"
            :disabled="!canEditArticle || disabled"
        />
      </div>

    </div>

    <!-- modal article-->
    <modal-article
        :article.sync="currentArticle"
        :title="('id' in currentArticle)?$t('EditArticle'):$t('NewArticle')"
        :isOpen.sync="articleModalShow"
        @submitValidatedArticle="submitValidatedArticleLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '@/utils/filter'
import { isObject } from '@/utils/utils'

import ModalArticle from '@/components/prompt/Article'
import ArticleForm from '@/components/form/Article'
import FieldSelect from '../input/Select2.vue'
import useAPI from '../../utils/useAPI'
import store from '@/store'
import ButtonExternalLink from '../button/ExternalLink'

export default {
  components: {
    ModalArticle,
    ArticleForm,
    ValidationObserver,
    FieldSelect,
    ButtonExternalLink
  },
  props: {
    displayLabel: {
      type: Boolean,
      default: true
    },
    listIndex: {
      type: Number,
      default: -1
    },
    article: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const articleLocal = ref(null)
    const articleModalShow = ref(false)
    const submitArticleFormRef = ref(null)
    const currentArticle = ref({})
    const componentKey = ref(0)

    // const selectedArticle = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteArticles, currentBreakPoint } = useAPI()

    const canEditArticle = computed(() => {
      return articleLocal.value != null
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(articleLocal, (val) => {
      if (val != null) {
        // console.log(val)

        emit('update:article', val)
      }
    })

    watch(articleModalShow, () => {
      if (articleModalShow.value == false) {
        resetArticle()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseArticleModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitArticleFormRef.value.click()
      }
    }

    const resetArticle = () => {
      currentArticle.value = JSON.parse(JSON.stringify({}))
    }

    const selectArticle = () => {
      // console.log("ok")
      // console.log(articleLocal.value)
      // if (!props.disabled && canEditArticle.value) {
      //   currentArticle.value = JSON.parse(JSON.stringify(
      //       articles.value.find(art => art.id == (isObject(articleLocal.value) ? articleLocal.value.id : articleLocal.value))
      //   ))
      //   articleModalShow.value = true
      // }

      if (!props.disabled && canEditArticle.value) {
        if (articleLocal.value != null) {
          store.dispatch('article/getArticle', articleLocal.value.id)
              .then(article => {
                currentArticle.value = article
              })

            // currentArticle.value = JSON.parse(JSON.stringify(
            //     articles.value.find(art => art.id == (isObject(articleLocal.value) ? articleLocal.value.id : articleLocal.value))
            // ))
        } else {
          resetArticle()
        }
        articleModalShow.value = true
      } else if (articleLocal.value == null) {
        // New
        articleModalShow.value = true
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      if (props.article != null) {
        articleLocal.value = JSON.parse(JSON.stringify(props.article))
        componentKey.value++
      }
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetArticle()

    return {
      // Components
      capitalize,

      // Data
      articleLocal,
      articleModalShow,
      submitArticleFormRef,
      currentArticle,
      componentKey,

      // Computed
      autocompleteArticles,
      canEditArticle,
      currentBreakPoint,

      // Methods
      tryCloseArticleModal,
      selectArticle
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    submitValidatedArticleLocal () {
      // console.log(this.currentArticle)

      if ('id' in this.currentArticle) {
        // Edit
        this.$store.dispatch('article/updateArticle', this.currentArticle)
            .then(response => {
              this.$emit('update:article', response.data)
              this.$nextTick(() => this.articleModalShow = false)
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        // New
        this.$store.dispatch('article/addArticle', this.currentArticle)
            .then(response => {
              this.articleLocal = response.data
              this.componentKey++

              this.articleModalShow = false
            })
            .catch(error => {
              console.log(error)
            })
      }

    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>