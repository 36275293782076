<template>
  <div class="col-md-8">
    <!--      Mobile Search + Views -->
    <div class="d-flex d-md-none mb-1 mb-md-0">

      <!--      Search-->
      <b-input-group class="input-group-merge">
        <b-form-input
            :placeholder="$t('Search')"
            class="search-product"
            v-model="localSearchQuery"
        />
        <b-input-group-append is-text>
          <icon
              icon="search"
              class="text-muted"
          />
        </b-input-group-append>
      </b-input-group>

      <!--      Parameters-->
      <!--      <div-->
      <!--          v-if="allowViewSettings"-->
      <!--          class="my-auto d-block d-md-none ml-1"-->
      <!--      >-->
      <!--        <search-button-->
      <!--            class="text-nowrap"-->
      <!--            icon="cog"-->
      <!--            size="sm"-->
      <!--            variant="outline-primary"-->
      <!--            :with-icon="true"-->
      <!--            style="height: 29.22px;"-->

      <!--            @click="$emit('toggleParameterDetailsOpen')"-->
      <!--        />-->
      <!--      </div>-->

      <!--      Views-->
      <div class="my-auto">
        <b-form-radio-group
            v-if="!readOnly"
            v-model="selectedView"
            class="ml-1 list item-view-radio-group my-auto"
            buttons
            size="sm"
            button-variant="outline-primary"
            @input.native="$emit('update:selectedView', $event.target.value)"
        >
          <b-form-radio
              v-for="option in displayAvailableViews"
              :key="option.value"
              :value="option.value"
          >
            <icon
                :icon="option.icon"
            />
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </div>

    <!--    Web + mobile remaining -->
    <div class="d-flex justify-content-between mb-1 mb-md-0">

      <!--      Sorts-->
      <div class="my-auto mr-2">
        <search-button
            v-if="selectedView != 'map'"
            class="text-nowrap"
            icon="arrows-alt-v"
            size="sm"
            variant="outline-primary"
            :title="capitalize($tc('sort', 2)) + ' ('+sortsAPI.length+')'"

            @click="$emit('toggleSortDetailsOpen')"
        />
      </div>

      <!--      Filters-->
      <div class="my-auto mr-2">
        <search-button
            class="text-nowrap"
            icon="filter"
            size="sm"
            variant="outline-primary"
            :title="capitalize($tc('filter', 2)) + ' ('+filtersAPI.length+')'"

            @click="$emit('toggleFilterDetailsOpen')"
        />
      </div>

      <!--      Parameters-->
      <div
          v-if="allowViewSettings"
          class="my-auto mr-2"
      >
        <search-button
            class="text-nowrap"
            icon="cog"
            size="sm"
            variant="outline-primary"
            :with-icon="true"
            style="height: 29.22px;"

            @click="$emit('toggleParameterDetailsOpen')"
        />
      </div>

      <!--      Search-->
      <div class="d-none d-md-block my-auto w-100 mr-2">
        <b-input-group
            class="input-group-merge"
            size="sm"
        >
          <b-form-input
              :placeholder="$t('Search')"
              class="search-product"
              v-model="localSearchQuery"
          />
          <b-input-group-append is-text>
            <icon
                icon="search"
                class="text-muted"
            />
          </b-input-group-append>
        </b-input-group>
      </div>

      <!--      Views-->
      <div class="d-none d-md-block my-auto mr-2">
        <b-form-radio-group
            v-if="!readOnly"
            v-model="localSelectedView"
            class="list item-view-radio-group my-auto"
            buttons
            size="sm"
            button-variant="outline-primary"
            @input.native="$emit('update:selectedView', $event.target.value)"
        >
          <b-form-radio
              v-for="option in displayAvailableViews"
              :key="option.value"
              :value="option.value"
          >
            <icon
                :icon="option.icon"
            />
          </b-form-radio>
        </b-form-radio-group>
      </div>

      <!--      Add buttons-->
      <div class="my-auto">
        <div class="d-flex">
          <add-button
              @click="$emit('addItem')"
              v-if="allowAdd"
              :withIcon="true"
              :isLoading="isAddButtonsLoading"
              :text="textButtonAdd"
              :is-disabled="isButtonAddDisabled"
              size="sm"
          />

          <upload-button
              @click="$emit('addItemByUpload')"
              v-if="allowAddByUpload"
              :withText="false"
              :withIcon="true"
              :isLoading="isAddButtonsLoading"
              class="ml-50"
              size="sm"
              style="height: 29.22px;"
          />

          <payment-button
              @click="$emit('addPayment')"
              v-if="allowPayment"
              :withText="false"
              :withIcon="true"
              :isLoading="isPaymentLoading"
              class="ml-50"
              size="sm"
              style="height: 29.22px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import AddButton from '../../button/Add.vue'
import UploadButton from '../../button/Upload.vue'
import SearchButton from '../../button/Button.vue'
import PaymentButton from '../../button/Payment.vue'
import { capitalize } from '../../../utils/filter'

export default {
  components: { SearchButton, UploadButton, AddButton, PaymentButton },
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    allowPayment: {
      type: Boolean,
      default: false
    },
    isAddButtonsLoading: {
      type: Boolean,
      default: false
    },
    isPaymentLoading: {
      type: Boolean,
      default: false
    },
    isButtonAddDisabled: {
      type: Boolean,
      default: false
    },
    textButtonAdd: {
      type: String,
      default: ''
    },
    filtersAPI: {
      type: Array,
      default: () => []
    },
    sortsAPI: {
      type: Array,
      default: () => []
    },
    availableViews: {
      type: Array,
      default: () => []
    },
    selectedView: {
      type: String,
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    allowViewSettings: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localSearchQuery = ref(props.searchQuery)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const localSelectedView = computed({
      get () {
        return props.selectedView
      },
      set (val) {
        emit('update:selectedView', val)
      }
    })

    const displayAvailableViews = computed(() => {
      if (localSelectedView.value == 'kanban') {
        return [{
          value: 'kanban',
          icon: 'pause'
        }]
      } else {
        if (props.availableViews.length) {
          let output = []
          props.availableViews.forEach(v => {
            if (!output.some(t=>t.value == v.type)) {
              if (v.type == 'list') {
                output.push({
                  value: 'list',
                  icon: 'list'
                })
              } else if (v.type == 'grid') {
                output.push({
                  value: 'grid',
                  icon: 'th-large'
                })
              } else if (v.type == 'kanban') {
                output.push({
                  value: 'kanban',
                  icon: 'pause'
                })
              } else if (v.type == 'map') {
                output.push({
                  value: 'map',
                  icon: 'map-marked-alt'
                })
              }
            }
          })

          return output
        } else {
          return [
            {
              value: 'list',
              icon: 'list'
            }, {
              value: 'grid',
              icon: 'th-large'
            }
          ]
        }

      }

      // let displayAvailableViews = []
      //
      // props.availableViews.forEach(v => {
      //   if (v.type == 'list' && displayAvailableViews.some(v => v.value == 'list') === false) {
      //     displayAvailableViews.push({
      //       value: 'list',
      //       icon: 'list'
      //     })
      //   } else if (v.type == 'grid' && displayAvailableViews.some(v => v.value == 'grid') === false) {
      //     displayAvailableViews.push({
      //       value: 'grid',
      //       icon: 'th-large'
      //     })
      //   } else if (v.type == 'kanban' && displayAvailableViews.some(v => v.value == 'kanban') === false) {
      //     displayAvailableViews.push({
      //       value: 'kanban',
      //       icon: 'pause'
      //     })
      //   }
      //
      // })
      //
      // return displayAvailableViews
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    let delayTimer
    watch(localSearchQuery, val => {
      clearTimeout(delayTimer)
      delayTimer = setTimeout(() => {
        emit('update:searchQuery', val)
      }, 500)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      localSearchQuery,

      // Computed
      localSelectedView,
      displayAvailableViews,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>