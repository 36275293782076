<template>
  <b-row>
    <b-col cols="12">
      <b-pagination
          v-model="localPage"
          :total-rows="itemsContext?itemsContext.total_results:0"
          :per-page="itemsContext?itemsContext.per_page:25"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
      >
        <template #prev-text>
          <icon icon="chevron-left"/>
        </template>
        <template #next-text>
          <icon icon="chevron-right"/>
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    itemsContext: {
      type: Object,
      default: () => {}
    },
    page: {
      type: Number,
      default: 1
    }
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const localPage = computed({
      get () {
        return props.page
      },
      set (val) {
        emit('update:page', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      localPage,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>