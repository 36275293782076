<template>
  <div
      v-if="isReady"
      class="w-100 overflow-x-scroll"
  >

    <div class="w-100 d-flex flex-column justify-content-start flex-md-row">

      <div
          :class="{'ml-5':columnIndex>0}"
          v-for="(column, columnIndex) in itemsLocal"
          :key="column.title"
          style="width: 230px"
      >
        <div
            class="mb-2 p-50 bg-white rounded"
        >
          <div
              class="d-flex"
              v-b-tooltip.hover
              :title="column.title"
          >
            <div class="text-truncate"><b>{{ column.title }}</b></div>
            <div v-if="column.items" class="ml-50">
              ({{ column.total_results }})
            </div>
          </div>
          <div v-if="column.amount != '-'">
            <b>{{ currency(column.amount) }}</b>
          </div>
        </div>

        <draggable
            tag="ul"
            group="all-groups"
            class="draggable-list pl-0"
            ghost-class="moving-card"
            filter=".action-button"
            :list="column.items"
            :animation="200"
            :data-column-index="columnIndex"
            @end="change"
        >
          <li
              v-for="item in column.items"
              :key="item.id"
              :data-column-index="columnIndex"
              :data-item-id="item.id"
              :data-item-amount="item[currentView.kanbanColumnAmount]"
              class="mb-1 list-unstyled cursor-move"
          >
            <slot
                name="kanbanItemView"
                :item="item"
            />
          </li>

        </draggable>


      </div>

    </div>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onBeforeUnmount } from '@vue/composition-api'
import { currency } from '../../../utils/filter'

import draggable from 'vuedraggable'
import store from '../../../store'
import { integer } from '../../../utils/validations/validations'

export default {
  components: {
    draggable
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    currentView: {
      type: Object,
      default: () => {}
    },
    context: {
      type: Object,
      default: () => {}
    },
    page : {
      type: Number,
      default: 1
    }
  },
  setup (props, { emit }) {
    // console.log(JSON.parse(JSON.stringify(props.currentView)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const itemsLocal = ref(JSON.parse(JSON.stringify(props.items)))
    const tryToUpdateItem = ref({})
    const scrollTopPosition = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return itemsLocal.value && itemsLocal.value.length && ('items' in itemsLocal.value[0])
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.items, val => {
      // console.log(val)
      itemsLocal.value = JSON.parse(JSON.stringify(val))
    }, { deep: true })

    watch(scrollTopPosition, val => {
      let scrollHeight = Math.max(
          document.body.scrollHeight, document.body.offsetHeight,
          document.documentElement.clientHeight, document.documentElement.scrollHeight,
          document.documentElement.offsetHeight) - document.documentElement.clientHeight

      if ((100 * val) / scrollHeight >= 80 && !store.getters['app/isLoading']) {
        let newPage = parseInt(JSON.parse(JSON.stringify(props.page)))
        emit('update:page', ++newPage)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const change = (change) => {
      tryToUpdateItem.value = {
        old_column_index: parseInt(change.item.attributes[0].value),
        old_column_position: change.oldIndex,
        // item_id: parseInt(change.item.attributes[1].value),
        new_column_index: parseInt(change.to.attributes[0].value),
        new_column_position: change.newIndex,
        not_updated_object: null,
        new_column_object_title: null
      }

      let kanbanSortByArray = props.currentView.kanbanSortBy.split('_')
      tryToUpdateItem.value.not_updated_object = JSON.parse(JSON.stringify(itemsLocal.value[tryToUpdateItem.value.new_column_index].items[tryToUpdateItem.value.new_column_position]))
      tryToUpdateItem.value.new_column_object_title = itemsLocal.value[tryToUpdateItem.value.new_column_index].title

      // let object = JSON.parse(JSON.stringify(itemsLocal.value[tryToUpdateItem.value.new_column_index].items[tryToUpdateItem.value.new_column_position]))
      // object[kanbanSortByArray[0]][kanbanSortByArray[1]] = itemsLocal.value[tryToUpdateItem.value.new_column_index].title
      // tryToUpdateItem.value.object = object

      itemsLocal.value[tryToUpdateItem.value.old_column_index].amount -= parseFloat(change.item.attributes[2].value)
      itemsLocal.value[tryToUpdateItem.value.new_column_index].amount += parseFloat(change.item.attributes[2].value)

      // console.log(props.currentView)
      // console.log(props.currentView.kanbanSortBy)

      emit('tryToUpdateItem', tryToUpdateItem.value)
    }

    const revert = () => {
      let moved = itemsLocal.value[tryToUpdateItem.value.new_column_index].items.splice(tryToUpdateItem.value.new_column_position, 1)
      itemsLocal.value[tryToUpdateItem.value.old_column_index].items.splice(tryToUpdateItem.value.old_column_position, 0, moved[0])

      itemsLocal.value[tryToUpdateItem.value.old_column_index].amount += parseFloat(moved[0][props.currentView.kanbanColumnAmount])
      itemsLocal.value[tryToUpdateItem.value.new_column_index].amount -= parseFloat(moved[0][props.currentView.kanbanColumnAmount])
    }

    const handleScroll = () => {
      scrollTopPosition.value = window.scrollY
      // let percent = ((100 * window.scrollY) / scrollHeight).toFixed(2)
      // console.log(percent + '%', window.scrollY + '/' + scrollHeight)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      window.addEventListener('scrollend', handleScroll)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('scrollend', handleScroll)
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data
      itemsLocal,

      // Computed
      isReady,

      // Methods
      change,
      revert,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.draggable-list {
  min-height: 100px;
}

/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.moving-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
</style>