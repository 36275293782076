<template>
  <div class="col-md-12">
    <b-card class="baseHeaderFilterDetails">
      <div
          v-for="(filter, indexFilter) in localFilters"
          :key="'filter_'+indexFilter"
          :class="{'pb-2': isShowedFilter(filter)}"
      >
        <filter-detail
            v-if="isShowedFilter(filter)"
            :filter.sync="filter"
            :section-index="indexFilter"
            :available-filters="availableFilters"

            @removeFilter="removeFilter(indexFilter)"
        />
      </div>


      <button-add
          size="sm"
          @click="addNewFilter"
          :text="capitalize($t('addFilter'))"
      />
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../utils/filter'

import FilterDetail from './components/_FilterDetail.vue'
import ButtonAdd    from '../../button/Add.vue'

export default {
  components: { FilterDetail, ButtonAdd },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    availableFilters: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    console.log('A')
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localFilters = ref(JSON.parse(JSON.stringify(props.filters)))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    // const enabledFilters = computed(() => {
    //   let output = []
    //
    //   localFilters.value.forEach((f, filterIndex) => {
    //     if (
    //         f.sectionLogicOperator != null &&
    //         f.sectionFilters.length != 0
    //     ) {
    //       f.sectionFilters.forEach(sf => {
    //         if (
    //             sf.logicOperator != null &&
    //             sf.field != null &&
    //             (
    //                 (
    //                     sf.name != null &&
    //                     sf.value != null
    //                 ) ||
    //                 sf.operator != null
    //             )
    //         ) {
    //           if (output[filterIndex] !== undefined) {
    //             output[filterIndex].sectionFilters.push(sf)
    //           } else {
    //             output.push(f)
    //           }
    //         }
    //       })
    //     }
    //
    //   })
    //
    //   return output
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(enabledFilters, val => {
    //   // console.log(val)
    //
    //   if (JSON.stringify(val) != JSON.stringify(props.filters)) {
    //     // console.log('différents !')
    //     // emit('update:filters', val)
    //   }
    //
    // }, { deep: true })

    const currentFilter = ref()
    watch(localFilters, (oldVal, newVal) => {
      if (JSON.stringify(newVal) != currentFilter.value) {
        console.log(JSON.parse(JSON.stringify(localFilters.value)))
        let filtersOk = 0
        let filtersLength = 0

        newVal.forEach(f => {
          f.sectionFilters.forEach(s => {
            if (
                s.field != null &&
                s.operator != null
            ) {
              filtersOk++
            }
            filtersLength++
          })
        })
        console.log(JSON.parse(JSON.stringify(newVal)))

        if (filtersOk == filtersLength) {
          console.log('ok', JSON.parse(JSON.stringify(filtersOk)))
          emit('update:filters', newVal)
        }
      }


      currentFilter.value = JSON.stringify(newVal)

    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addNewFilter = () => {
      // console.log(JSON.stringify(localFilters.value))
      localFilters.value.push({
        sectionLogicOperator: 'AND',
        sectionFilters: [{
          logicOperator: 'AND',
          field: null,
          value: ''
          // operator: {}
        }]
      })
      //     localFilters.value.push({})
      console.log(JSON.parse(JSON.stringify(localFilters.value)))
    }

    const removeFilter = (indexFilter) => {
      console.log('remove filter index ' + indexFilter)
      localFilters.value.splice(indexFilter, 1)
    }

    const isShowedFilter = (filter) => {
      let af = props.availableFilters.find(af => af.field == filter.sectionFilters[0].field)

      return af === undefined || !('show' in af) || af.show == true
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      localFilters,

      // Methods
      addNewFilter,
      removeFilter,
      isShowedFilter,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>