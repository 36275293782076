<template>
  <div class="row mb-2">
    <left
        :title="title"
        :items="items"
        :items-context="itemsContext"
        :display-custom-fields="displayCustomFields"
    />

    <right
        :search-query.sync="localSearchQuery"
        :filters-a-p-i="filtersAPI"
        :sorts-a-p-i="sortsAPI"
        :available-views="availableViews"
        :read-only="readOnly"
        :selected-view.sync="localSelectedView"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :allow-payment="allowPayment"
        :is-button-add-disabled="isButtonAddDisabled"

        @toggleFilterDetailsOpen="isFilterDetailsOpen = !isFilterDetailsOpen"
        @toggleSortDetailsOpen="isSortDetailsOpen = !isSortDetailsOpen"
        @toggleParameterDetailsOpen="isViewParametersOpen = !isViewParametersOpen"
        @addItem="$emit('addItem')"
        @addItemByUpload="$emit('addItemByUpload')"
    />

    <transition name="fade">
      <sort-details
          v-if="isSortDetailsOpen"
          :sorts.sync="localSorts"
          :available-sorts="availableSorts"
          class="mt-50"
      />
    </transition>

    <transition name="fade">
      <filter-details
          v-if="isFilterDetailsOpen"
          :filters.sync="localFilters"
          :available-filters="availableFilters"
          class="mt-50"
      />
    </transition>

    <transition name="fade">
      <view-parameters
          v-if="isViewParametersOpen"
          ref="viewParametersRef"
          class="mt-50"
          :module="module"

          @setDefaultModuleView="setDefaultModuleView"
          @resetPayload="$emit('resetPayload')"
          @addModuleView="addModuleView(selectedView, module)"
          @editModuleView="editModuleView"
          @deplicateModuleView="deplicateModuleView"
          @deleteModuleView="deleteModuleViewAlert"
      />
    </transition>

    <module-view-form-prompt
        :module-view.sync="currentModuleView"
        :available-filters="availableFilters"
        :available-sorts="availableSorts"
        :available-global-amounts="availableGlobalAmounts"
        :title="('id' in currentModuleView)?$t('EditModuleView'):$t('NewModuleView')"
        :is-open.sync="isModuleViewModalShow"
        @submitValidatedModuleView="submitValidatedModuleViewLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinModuleViews } from '../mixinModuleViews'

import Left from './Left.vue'
import Right from './Right.vue'
import FilterDetails from './FilterDetails.vue'
import SortDetails from './SortDetails.vue'
import ViewParameters from './ViewParameters.vue'
import StartViewParameters from '../../base2/header/ViewParameters.vue'

export default {
  components: { StartViewParameters, ViewParameters, SortDetails, FilterDetails, Right, Left },
  mixins: [mixinModuleViews],
  props: {
    title: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsContext: {
      type: Object,
      default: () => {}
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },
    searchQuery: {
      type: String,
      default: ''
    },
    filters: {
      type: Array,
      default: () => []
    },
    filtersAPI: {
      type: Array,
      default: () => []
    },
    availableFilters: {
      type: Array,
      default: () => []
    },
    availableGlobalAmounts: {
      type: Array,
      default: () => []
    },
    sortsAPI: {
      type: Array,
      default: () => []
    },
    sorts: {
      type: Array,
      default: () => []
    },
    availableSorts: {
      type: Array,
      default: () => []
    },
    availableViews: {
      type: Array,
      default: () => []
    },
    selectedView: {
      type: String,
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    module: {
      type: String
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    allowPayment: {
      type: Boolean,
      default: false
    },
    isButtonAddDisabled: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isSortDetailsOpen = ref(false)
    const isFilterDetailsOpen = ref(false)
    const isViewParametersOpen = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const localSearchQuery = computed({
      get () {
        return props.searchQuery
      },
      set (val) {
        console.log("W0")
        emit('update:searchQuery', val)
      }
    })

    const localSorts = computed({
      get () {
        return props.sorts
      },
      set (val) {
        console.log("W1")
        emit('update:sorts', val)
      }
    })

    const localFilters = computed({
      get () {
        return props.filters
      },
      set (val) {
        console.log("W2")
        emit('update:filters', val)
      }
    })

    const localSelectedView = computed({
      get () {
        return props.selectedView
      },
      set (val) {
        console.log("W3")
        emit('update:selectedView', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isSortDetailsOpen, val => {
      // console.log(val)
      if (val == true) {
        // Close others
        isFilterDetailsOpen.value = false
        isViewParametersOpen.value = false
      }
    })

    watch(isFilterDetailsOpen, val => {
      // console.log(val)
      if (val == true) {
        // Close others
        isSortDetailsOpen.value = false
        isViewParametersOpen.value = false
      }
    })

    watch(isViewParametersOpen, val => {
      // console.log(val)
      if (val == true) {
        // Close others
        isFilterDetailsOpen.value = false
        isSortDetailsOpen.value = false
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isFilterDetailsOpen,
      isSortDetailsOpen,
      isViewParametersOpen,

      // Computed
      localSearchQuery,
      localSorts,
      localFilters,
      localSelectedView,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>