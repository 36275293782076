<template>
  <b-col cols="12">

    <!--Line titles-->
    <div class="d-none d-lg-flex">
      <b-row class="flex-grow-1 px-1 mr-1">
        <b-col
            cols="12"
            md="7"
        >
          {{ capitalize($t('article')) }}
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          {{ capitalize($t('unitPrice')) }}
        </b-col>
        <b-col
            cols="12"
            md="2"
        >
          {{ capitalize($t('quantity')) }}
        </b-col>
        <!--        <b-col-->
        <!--            cols="12"-->
        <!--            md="2"-->
        <!--        >-->
        <!--          {{ capitalize($t('price')) }}-->
        <!--        </b-col>-->
      </b-row>
      <div class="form-product-action-col"/>
    </div>

    <div class="d-flex border rounded">
      <b-row
          class="flex-grow-1 px-2 pt-2 w-100"
      >

        <!--        Article-->
        <b-col
            cols="12"
            md="7"
        >
          <field-article
              :displayLabel="!['md','lg', 'xl'].includes(currentBreakPoint)"
              :article.sync="product.article"
              :listIndex="index"
              :disabled="disableEdit"
          />
        </b-col>

        <!--        Unit price-->
        <b-col
            cols="12"
            md="3"
        >
          <field
              :name="'unitPrice'"
              :listIndex="index"
              :displayLabel="!['md','lg', 'xl'].includes(currentBreakPoint)"
              :rules="'required'"
              :model.sync="product.unitPrice"
              :key="componentKey"
              :disabled="disableEdit"
              @input="changePreTaxAmount('unitPrice', $event)"
          />
        </b-col>

        <!--        Quantity-->
        <b-col
            cols="12"
            md="2"
        >
          <field
              :name="'quantity'"
              :listIndex="index"
              :displayLabel="!['md','lg', 'xl'].includes(currentBreakPoint)"
              :rules="'required'"
              :model.sync="product.quantity"
              :disabled="disableEdit"
              @input="changePreTaxAmount('quantity', $event)"
          />
        </b-col>

        <b-col
            v-if="!viewMore"
            cols="12"
        >
          <div class="d-flex justify-content-between text-secondary">
            <div class="text-truncate"
                 style="max-width: 400px;">
              <span v-if="product.description && product.description.replace(/<[^>]*>?/gm, '') != null && product.description.replace(/<[^>]*>?/gm, '') != ''">
                <i>
                  {{ capitalize($t('description')) }} :
                  {{ product.description.replace(/<[^>]*>?/gm, '') }}
                </i>
              </span>
            </div>
            <div class="">
              <span class="">
                <i>{{ $t('PreTaxAmount:') }}
                  <b>{{ currency(product.preTaxAmount) }}</b>
                </i>
              </span>
            </div>
          </div>


        </b-col>

        <!--        Description-->
        <transition name="fade">
          <b-col
              v-if="viewMore"
              cols="12"
              md="8"
          >
            <field-textarea
                :model.sync="product.description"
                :name="'description'"
                :placeholder="'description'"
                style="overflow: auto; max-height: 135px;"
                :key="componentKey"
                :disabled="disableEdit"
            />
          </b-col>
        </transition>

        <!--        Amounts-->
        <transition name="fade">
          <b-col
              v-if="viewMore"
              cols="12"
              md="4"
              class="d-flex mt-md-1"
          >
            <div class="w-100"
                 style="max-width: 12rem">
              <div
                  v-if="product.discount"
                  class="d-flex align-content-center justify-content-between"
              >
                <p class="invoice-total-title">
                  {{ $t('Discount:') }}
                </p>
                <p
                    v-if="product.isDiscountPercent"
                    class="invoice-total-amount"
                >
                  <b>{{ percent(product.discount) }}</b>
                </p>
                <p
                    v-else
                    class="invoice-total-amount"
                >
                  <b>{{ currency(product.discount) }}</b>
                </p>
              </div>

              <!--            Pre tax amount-->
              <div
                  class="d-flex align-content-center justify-content-between">
                <p class="invoice-total-title">
                  {{ $t('PreTaxAmount:') }}
                </p>
                <p class="invoice-total-amount">
                  <b>{{ currency(product.preTaxAmount) }}</b>
                </p>
              </div>

              <!--            <VAT-->
              <div class="d-flex align-content-center justify-content-between">
                <p class="invoice-total-title">
                  {{ $t('VAT:') }}
                </p>
                <p class="invoice-total-amount">
                  <b>{{ currency(product.vat) }}</b>
                </p>
              </div>
              <hr class="my-50">

              <!--            Total amount-->
              <div class="d-flex align-content-center justify-content-between">
                <p class="invoice-total-title">
                  {{ $t('TotalAmount:') }}
                </p>
                <p class="invoice-total-amount">
                  <b>{{ currency(product.totalAmount) }}</b>
                </p>
              </div>
            </div>
          </b-col>
        </transition>

      </b-row>

      <!--      Right col-->
      <div
          style="width: 36.5px; margin-left: -8px"
          class="d-flex flex-column justify-content-between border-left py-50 px-25 text-center cursor-pointer"
      >
        <div @click="viewMore= !viewMore">

          <button-delete
              size="sm"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :disabled="disableEdit || !allowRemove"
              @click.native.stop="$emit('removeProduct')"
          />
          <br/>

          <button-view-more
              size="sm"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :isOpen="viewMore"
          />
        </div>

        <div @click="viewMore= !viewMore"
             class="h-100"></div>

        <button-settings
            size="sm"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :id="'popover-product-'+index"
            @click.native.stop="showPopover = !showPopover"
        />

        <!-- Setting Item Form -->
        <b-popover
            :show.sync="showPopover"
            :target="'popover-product-'+index"
            placement="top"
            triggers="hover"
        >
          <b-row>
            <b-col cols="12">
              <field-discount
                  :name="'discount'"
                  :discount.sync="product.discount"
                  :isDiscountPercent.sync="product.isDiscountPercent"
                  @input="changePreTaxAmount('discount', $event)"
                  :disabled="disableEdit"
              />
            </b-col>


            <b-col cols="6">
              <field-date
                  :name="'deliveryDate'"
                  :model.sync="product.deliveryDate"
                  class="w-100"
              />
            </b-col>
            <b-col cols="6">
              <field-select
                  name="VAT"
                  :model.sync="product.vatRate"
                  :options="autocompleteVats"
                  label="name"
                  :placeholder="$t('Choose an VAT')"
                  :clearable="false"
                  :disabled="disableEdit"
                  :append-to-body="false"

                  @input="changePreTaxAmount('vat', $event)"
              />
              <!--                            <field-select-->
              <!--                                :name="'VAT'"-->
              <!--                                :model.sync="product.vatRate"-->
              <!--                                :options="autocompleteVats"-->
              <!--                                :label="'name'"-->
              <!--                                :placeholder="$t('Choose an VAT')"-->
              <!--                                :clearable="false"-->
              <!--                                :disabled="disableEdit"-->
              <!--                                :appendToBody="false"-->
              <!--                                @input="changePreTaxAmount('vat', $event)"-->
              <!--                            />-->
            </b-col>
          </b-row>
        </b-popover>

      </div>
    </div>

        <field-select
            class="hidden"
            name="VAT"
            :model.sync="product.vatRate"
            :options="autocompleteVats"
            label="name"
            :placeholder="$t('Choose an VAT')"
            :clearable="false"
            :disabled="disableEdit"
            :append-to-body="false"
            :get-detailed-object="true"
            dispatch-url="vat/getVat"

            @input="changePreTaxAmount('vat', $event)"
        />

  </b-col>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'
import { isObject } from '@/utils/utils'

import FieldArticle from '../../../input/Article'
import Field from '@/components/input/Input'
import FieldDiscount from '@/components/input/Discount'
import FieldSelect from '../../../input/Select2'
import FieldTextarea from '@/components/input/Textarea'
import FieldDate from '@/components/input/Date'
import store from '@/store'
import ButtonDelete from '../../../button/Delete'
import ButtonViewMore from '../../../button/ViewMore'
import ButtonSettings from '../../../button/Settings'
import { getObjectId } from '../../../../utils/utils'
import useAPI from '../../../../utils/useAPI'

export default {
  components: {
    FieldArticle,
    Field,
    FieldDiscount,
    FieldSelect,
    FieldTextarea,
    FieldDate,
    ButtonDelete,
    ButtonViewMore,
    ButtonSettings
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    allowRemove: {
      type: Boolean
    },
    disableTVA: {
      type: Boolean,
      default: false
    }
    // disableEdit: {
    //   type: Boolean,
    //   default: true
    // }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const showPopover = ref(false)
    const componentKey = ref(0)
    const viewMore = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })
    const { autocompleteVats } = useAPI()

    const disableEdit = computed(() => {
      return ('parent' in props.product) && props.product.parent != null
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(viewMore, (val) => {
      if (val) {
        emit('updateSize')
      } else {
        setTimeout(() => {
          emit('updateSize')
        }, 500)
      }

    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const changePreTaxAmount = (input, value) => {
      // console.log(input, value)
      // console.log(props.product.vatRate)
      // console.log(JSON.parse(JSON.stringify(props.product)))
      // console.log(JSON.parse(JSON.stringify(parseFloat(props.product.unitPrice ? props.product.unitPrice : 0))))
      // let unitPrice = parseFloat(('unitPrice' in props.product) ? props.product.unitPrice : 0)
      // console.log(props.product.unitPrice)
      // console.log(parseFloat(props.product.unitPrice))

      let unitPrice = parseFloat(props.product.unitPrice ? props.product.unitPrice : 0)
      if (input == 'unitPrice' && value != null) {
        // console.log(value)
        // console.log(parseFloat(value ? value : 0))
        unitPrice = parseFloat(value ? value : 0)
        props.product.unitPrice = unitPrice
      }

      let quantity = parseFloat(props.product.quantity ? props.product.quantity : 0)
      if (input == 'quantity') {
        quantity = parseFloat(value ? value : 0)
      }

      let discount = parseFloat(props.product.discount ? props.product.discount : 0)
      let isDiscountPercent = props.product.isDiscountPercent
      if (input == 'discount') {
        if (typeof value === 'boolean') {
          isDiscountPercent = value
        } else {
          discount = parseFloat(value ? value : 0)
        }
      }

      if (discount != 0) {
        if (isDiscountPercent) {
          props.product.preTaxAmount = (unitPrice * quantity * (1 - discount / 100)).toFixed(2)
        } else {
          props.product.preTaxAmount = (unitPrice * quantity - discount).toFixed(2)
        }
      } else {
        // console.log(unitPrice * quantity)
        // console.log((unitPrice * quantity).toFixed(2))
        props.product.preTaxAmount = (unitPrice * quantity).toFixed(2)
      }

      // if (getObjectId(props.product.article) != 784 && props.product.preTaxAmount < 0) {
      //   props.product.preTaxAmount = 0
      // }

      if (!props.disableTVA) {

        if (isObject(props.product.vatRate) && ('rate' in props.product.vatRate)) {
          let vat = props.product.preTaxAmount * parseFloat(props.product.vatRate.rate)

          props.product.vat = isNaN(vat) ? 0 : (vat).toFixed(2)
          props.product.totalAmount =
              (parseFloat(props.product.preTaxAmount) +
                  parseFloat(parseFloat(props.product.vatRate.rate) * props.product.preTaxAmount)).toFixed(2)

        }
      } else {
        props.product.vat = 0
        props.product.totalAmount = parseFloat(props.product.preTaxAmount)
      }

      // console.log(JSON.parse(JSON.stringify(props.product)))
      emit('changePreTaxAmount')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data
      showPopover,
      componentKey,
      viewMore,

      // Computed
      currentBreakPoint,
      autocompleteVats,
      disableEdit,

      // Methods
      changePreTaxAmount,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    'product.article': function (newVal, oldVal) {
      // console.log(newVal)
      // console.log(oldVal)

      if (typeof newVal !== 'undefined') {
        if (
            (
                typeof oldVal !== 'undefined' &&
                newVal.id != oldVal.id
            ) ||
            typeof oldVal === 'undefined'
        ) {
          // console.log('Article change !')
          this.product.description = newVal.description

          if (newVal.unitPrice != null) {
            this.product.unitPrice = newVal.unitPrice
          }

          if (newVal.vatRate != null) {
            this.$store.dispatch('vat/getVat', newVal.vatRate.id)
                .then(vat => {
                  this.product.vatRate = vat
                })

          } else {
            this.$store.dispatch('vat/getVat', 1)
                .then(vatRate => {
                  this.product.vatRate = vatRate
                })
          }
          this.changePreTaxAmount('unitPrice', newVal.unitPrice)
          this.componentKey++
        }

      }

    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>