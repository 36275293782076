import store from '@/store'

export const useModuleView = () => {

  const submitValidatedModuleView = (moduleView) => {
    return new Promise((resolve, reject) => {
      if ('id' in moduleView && moduleView.id != null) {
        updateModuleView(moduleView)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewModuleView(moduleView)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewModuleView = (moduleView) => {
    return new Promise((resolve, reject) => {
      store.dispatch('moduleView/addModuleView', moduleView)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateModuleView = (moduleView) => {
    return new Promise((resolve, reject) => {
      store.dispatch('moduleView/updateModuleView', moduleView)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeModuleView = (moduleView) => {
    return new Promise((resolve, reject) => {
      store.dispatch('moduleView/removeModuleView', moduleView)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedModuleView,
    removeModuleView,
  }
}