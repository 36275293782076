<template>
  <div class="row">

    <div class="col-md-12">
      <select2
          :options="operatorList"
          :displayLabel="false"
          size="sm"
          :model.sync="operatorLocal"
          :clearable="false"
          :disabled="isDisabled"
          label="i18n"

          @input="parentUpdate"
      />
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import select2                  from '../../../input/Select2.vue'
import { capitalize }           from '../../../../utils/filter'
import i18n                     from '../../../../libs/i18n'

export default {
  components: { select2 },
  props: {
    sectionFilter: {
      type: Object,
      default: () => {}
    },
    correspondingAvailableFilter: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const operatorLocal = ref({
      value: props.sectionFilter.operator,
      i18n: capitalize(i18n.t(props.sectionFilter.operator))
    })

    // const operatorList = ref([
    //   {
    //     value: 1,
    //     i18n: capitalize(i18n.t('yes'))
    //   },
    //   {
    //     value: 0,
    //     i18n: capitalize(i18n.t('no'))
    //   },
    //   {
    //     value: 'is_empty',
    //     i18n: capitalize(i18n.t('is_empty'))
    //   },
    // ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isSelectedValueDisabled = computed(() => {
      if (
          props.isDisabled ||
          (
              operatorLocal.value != null &&
              (
                  operatorLocal.value.value == 'is_empty' ||
                  operatorLocal.value.value == 'is_not_empty'
              )
          )
      ) {
        return true
      } else {
        return false
      }
    })

    const operatorList = computed(() => {
      if ('booleanAccept' in props.correspondingAvailableFilter) {
        if (props.correspondingAvailableFilter.booleanAccept.length == 1 &&
            props.correspondingAvailableFilter.booleanAccept[0] == 'yes') {
          return [
            {
              value: 1,
              i18n: capitalize(i18n.t('yes'))
            },
          ]
        }
      } else {
        if (
            'booleanAllowNull' in props.correspondingAvailableFilter &&
            props.correspondingAvailableFilter.booleanAllowNull == false
        ) {
          return [
            {
              value: 1,
              i18n: capitalize(i18n.t('yes'))
            },
            {
              value: 0,
              i18n: capitalize(i18n.t('no'))
            }
          ]
        } else {
          return [
            {
              value: 1,
              i18n: capitalize(i18n.t('yes'))
            },
            {
              value: 0,
              i18n: capitalize(i18n.t('no'))
            },
            {
              value: 'is_empty',
              i18n: capitalize(i18n.t('is_empty'))
            },
          ]
        }
      }

    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const parentUpdate = () => {
      if (!props.isDisabled) {
        if (['is_empty'].includes(operatorLocal.value.value)) {
          emit('update:sectionFilter', {
            name: props.sectionFilter.name,
            logicOperator: props.sectionFilter.logicOperator,
            field: props.sectionFilter.field,
            operator: operatorLocal.value.value,
            subFields: props.sectionFilter.subFields,
            value: null
          })
        } else {
          emit('update:sectionFilter', {
            name: props.sectionFilter.name,
            logicOperator: props.sectionFilter.logicOperator,
            field: props.sectionFilter.field,
            operator: 'equal',
            subFields: props.sectionFilter.subFields,
            value: operatorLocal.value.value
          })
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      operatorLocal,
      operatorList,

      // Computed
      isSelectedValueDisabled,

      // Methods
      parentUpdate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>