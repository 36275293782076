<template>
  <generic-content
      :isLinkable="false"
      :isEditable="isEditable"
      :isPayable="isPayable"
      :isDeletable="isDeletable"
      :isDownloadable="isDownloadable"
      :isViewable="isViewable"
      :is-selected="isSelected"
      @click="isParentMethodPriority?$emit('click'):download()"
      @download="download"
      @toRoute="isParentMethodPriority?$emit('toRoute'):''"
      @view="$emit('view')"
      @edit="$emit('edit')"
      @pay="$emit('pay')"
      @delete="$emit('delete')"
  >
    <template #bodyContent>
      <div class="d-flex justify-content-between h5">
        <div class="text-truncate">
          {{ order.number?order.number:'-' }}
        </div>
        <div class="text-nowrap">
          {{ currency(order.preTaxAmount) }}
        </div>
      </div>
      <small class="d-flex justify-content-between">
        <div class="text-truncate">
          {{ order.supplierCompany._display }}
        </div>
        <div>
          {{ order.signedDocumentDate | moment("L")}}
        </div>
      </small>

    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '../../../utils/filter'

import GenericContent from './Content'

export default {
  components: {
    GenericContent
  },
  props: {
    order: {
      type: Object,
      default: () => { }
    },
    isDownloadable: {
      type: Boolean,
      default: true
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const contact = computed(() => {
      if (props.order.customerCompany != null) {
        return props.order.customerCompany
      } else {
        return props.order.customerIndividual
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const download = () => {
      if (props.order.quotes.length >= 1) {
        // console.log(props.salary.receipt.url)
        let link = document.createElement('a')
        link.setAttribute('target', '_blank')
        link.href = props.order.quotes[props.order.quotes.length-1].url
        link.click()
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data

      // Computed
      contact,

      // Methods
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>